<script>
import { ErrorMessage, Field } from 'vee-validate';

export default {
    name: 'CountrySelect',

    data() {
        return {
            internalValue: null,
        };
    },

    components: {
        ErrorMessage,
        Field,
    },

    props: {
        id: {
            type: String,
            required: true,
        },

        name: {
            type: String,
            required: true,
        },

        optionLabel: {
            type: String,
        },

        optionValue: {
            type: String,
        },

        placeholder: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        modelValue: {
            type: [Number, String, Object],
            default: null,
        },
    },

    computed: {
        countries() {
            return this.$store.state.countries.countriesCodeListRegistration;
        },
    },

    methods: {
        setInternalValue(value) {
            if (value) {
                this.internalValue = value;
            }
            return '';
        },
        reset() {
            this.internalValue = null;
        },
    },

    mounted() {
        if (this.modelValue) {
            this.internalValue = this.modelValue;
        }
    },
};
</script>

<template>
    <div class="input-box">
        <Field v-slot="{ handleChange, value }" :name="name">
            <!-- TODO Enhance this part -->
            {{ setInternalValue(value) }}
            <UtilsGenericDropDown
                v-model="internalValue"
                :disabled="disabled"
                :empty-filter-message="$t('Noresultsfound')"
                :empty-message="$t('Noresultsfound')"
                :filter-fields="['name']"
                :filter-placeholder="$t('chooseacountry')"
                :loading="loading"
                :modelValue="value"
                :option-label="optionLabel"
                :option-value="optionValue"
                :options="countries"
                :placeholder="placeholder"
                class="mb-0"
                close-on-scroll
                @change="$emit('update:modelValue', internalValue)"
                @update:modelValue="handleChange"
            />
        </Field>

        <ErrorMessage :name="name" class="text-danger" />
    </div>
</template>
