<template>
    <div>
        <div class="login-box">
            <LocLink to="/auth/login" class="login"> {{ $t('loginText') }} </LocLink>
            <LocLink class="signup" to="/auth/signup">{{ $t('signup') }} </LocLink>
        </div>
    </div>
</template>

<script>
export default {};
</script>
<style lang="scss">
.login-box {
    display: flex;
    gap: 24px;
    @include dir(rtl) {
        gap: 12px;
    }
    align-items: center;
    justify-content: flex-end;
    a {
        font-size: 14px;
        font-weight: 400;
        &.login {
            color: $color-secondary;
            font-size: 16px;
            font-weight: 600;
            @include hover(0.2) {
                color: $color-secondary-light;
            }
            @include dir(rtl) {
                font-size: 14px;
                @include media(1200) {
                    font-size: 12px;
                    padding: 12px 10px;
                }
            }

            @include active;
        }
        &.signup {
            border-radius: 5px;
            padding: 8px 16px;
            background: $color-secondary;

            color: #fff;
            text-align: center;
            font-size: 16px;
            font-weight: 600;

            @include dir(rtl) {
                font-size: 14px;
                @include media(1200) {
                    font-size: 12px;
                    padding: 12px 10px;
                }
            }
            @include hover(0.2) {
                background-color: $color-secondary-light;
            }
            @include active;
        }
    }
}
</style>
