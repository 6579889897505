export const useAuthStore = defineStore('auth', () => {
    const { $getApi } = useNuxtApp();

    const profile = ref(null);

    const getProfile = async () => {
        const res = await $getApi(`auth/profile`);
        profile.value = res.data?.data?.user;
    };

    const setProfile = data => {
        profile.value = data;
    };

    const getDocuments = async () => {
        const res = await $getApi(`auth/uploaded-documents`);
        return res.data?.data;
    };

    return {
        profile,
        setProfile,
        getProfile,
        getDocuments,
    };
});
