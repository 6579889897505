<template>
    <div class="headerSection bg-white scrollBar" :class="{ shadowBox: shadow }">
        <div class="container">
            <div class="row align-items-center d-flex justify-content-between">
                <div class="col-lg-1 col-md-5 col-5">
                    <div class="logo-box">
                        <LocLink to="/">
                            <img src="~/assets/img/logo.svg" alt="image" class="img-fluid" />
                        </LocLink>
                    </div>
                </div>

                <div class="col-lg-3 col-md-1 col-1" :class="[rtl ? 'me-auto' : 'ms-auto']">
                    <div class="profileBox-login" v-if="!loginBox">
                        <LayoutLoginBox v-if="login" />
                        <LayoutProfileBox v-else />
                    </div>
                </div>

                <div class="col-xl-2 col-lg-3 col-md-5 col-6 d-flex">
                    <ul class="social-links d-flex flex-row gap-3 align-items-center">
                        <span>
                            <img src="~/assets/img/or_sympol.svg" />
                        </span>

                        <li class="li">
                            <a href="https://x.com/acceptixedtch?s=21&t=3hrgwimf5NKEcWLpuXSBPA" target="_blank">
                                <img src="~/assets/img/icn_twitter_x_blue.svg" alt="Twitter" />
                            </a>
                        </li>
                        <li class="li">
                            <a href="https://www.facebook.com/AcceptixEdtch?mibextid=LQQJ4d" target="_blank">
                                <img src="~/assets/img/icn_facebook_blue.svg" alt="Facebook" />
                            </a>
                        </li>
                        <li class="li">
                            <a href="https://www.tiktok.com/@acceptixedtch?_t=8m9ambQ9Sr7&_r=1" target="_blank">
                                <img src="~/assets/img/icn_tiktok_blue.svg" alt="Tiktok" />
                            </a>
                        </li>
                        <li class="li">
                            <a href="https://www.instagram.com/acceptixedtch?igsh=djFtcjVranZ5aG9l" target="_blank">
                                <img src="~/assets/img/icn_instagram_blue.svg" alt="Twitter" />
                            </a>
                        </li>

                        <span>
                            <img src="~/assets/img/or_sympol.svg" />
                        </span>
                    </ul>

                    <LayoutLang />
                    <LayoutSideMenu :login="login" :loginBox="loginBox" />
                </div>
            </div>
        </div>
    </div>

    <div class="headerSection bg-white pb-3 hide-on-sm">
        <div class="col-12 d-flex justify-content-center">
            <div class="nav-box">
                <ul class="list-unstyled">
                    <li>
                        <LocLink to="/why-acceptix">{{ $t('whystudentcenter') }}</LocLink>
                    </li>
                    <li>
                        <a href="/#howWorkSection">{{ $t('howdowework') }}</a>
                    </li>
                    <li class="destinations">
                        <LocLink to="/destinations" class="d-flex align-items-center gap-2">
                            {{ $t('destinations') }}
                            <i class="arrow-icon"></i>
                        </LocLink>
                        <div class="destinations-box">
                            <ul>
                                <li v-for="item in destinations.slice(0, 10)" :key="item.id">
                                    <div class="d-flex gap-2 align-items-center" @click="filterBy('destination', item)">
                                        <div v-html="item.flag" class="flag"></div>
                                        {{ item.name }}
                                    </div>
                                </li>
                            </ul>
                            <NuxtLink class="see-all" to="/destinations">
                                {{ $t('advSearch.seeAll') }}
                            </NuxtLink>
                        </div>
                    </li>
                    <li class="universities">
                        <LocLink @click="resetFilters" to="/university" class="d-flex align-items-center gap-2"
                            >{{ $t('findUniversities') }}<i class="arrow-icon"></i
                        ></LocLink>
                        <div class="dropdown-box">
                            <h2 class="title">{{ $t('topUniversities') }}</h2>
                            <ul>
                                <li v-for="item in fixedUniversities" :key="item.id">
                                    <div class="d-flex gap-2 align-items-center" @click="filterBy('university', item)">
                                        <div class="flag">
                                            <img :src="item.logo" alt="Logo" />
                                        </div>
                                        <div class="content">
                                            <span class="name">{{ item.name }}</span>
                                            <div class="location-container">
                                                <img src="/svg/location.svg" alt="location" />
                                                <span class="location"
                                                    >{{ item.includes?.country?.name }},
                                                    {{ item.includes?.city?.name }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <NuxtLink @click="resetFilters" class="see-all" to="/university">
                                {{ $t('advSearch.seeAll') }}
                            </NuxtLink>
                        </div>
                    </li>
                    <li class="institutes">
                        <LocLink @click="resetFilters" to="/institute" class="d-flex align-items-center gap-2"
                            >{{ $t('findEnglishSchools') }}<i class="arrow-icon"></i
                        ></LocLink>
                        <div class="dropdown-box">
                            <h2 class="title">{{ $t('topEnglishSchools') }}</h2>
                            <ul>
                                <li v-for="item in fixedInstitutes" :key="item.id">
                                    <div class="d-flex gap-2 align-items-center" @click="filterBy('institute', item)">
                                        <div class="flag">
                                            <img :src="item.logo" alt="Logo" />
                                        </div>
                                        <div class="content">
                                            <span class="name">{{ item.name }}</span>
                                            <div class="location-container">
                                                <img src="/svg/location.svg" alt="location" />
                                                <span class="location"
                                                    >{{ item.includes?.country?.name }},
                                                    {{ item.includes?.city?.name }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <NuxtLink @click="resetFilters" class="see-all" to="/institute">
                                {{ $t('advSearch.seeAll') }}
                            </NuxtLink>
                        </div>
                    </li>
                    <li>
                        <LocLink to="https://academy.acceptix.com" target="_blank">{{
                            $t('trainingAndDevelopment')
                        }}</LocLink>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useCheckCategory } from '../../../../libs/shared/composables/useCheckCategory';
import { useCommonStore } from '../../../../libs/shared/store/search/common';
import { useUniversityStore } from '../../../../libs/shared/store/search/university';
import { useInstituteStore } from '../../../../libs/shared/store/search/institute';
const {
    categoryFilterCountries,
    getCategoryList,
    getCategoryCourses,
    categoryFilterUniversitiesOrInstitutes,
    categoryFilterClear,
} = useCheckCategory();

const commonStore = useCommonStore();
const { category, preventClear } = storeToRefs(commonStore);
const instituteStore = useInstituteStore();
const universityStore = useUniversityStore();
const { fixedUniversities } = storeToRefs(universityStore);
const { fixedInstitutes } = storeToRefs(instituteStore);

onMounted(async () => {
    await nextTick();
    universityStore.getFixedUniversities();
    instituteStore.getFixedInstitutes();
});

const resetFilters = () => {
    preventClear.value = false;
};

const filterBy = (type, item) => {
    // Clear filters
    categoryFilterClear.value();

    if (type === 'destination') {
        category.value = item.is_university ? 'university' : 'institute';
        if (!categoryFilterCountries.value.some(selected => selected.id === item.id)) {
            categoryFilterCountries.value.push({ id: item.id, text: item.name, type: 'country' });
        }
    } else if (type === 'university') {
        category.value = type;
        if (!categoryFilterUniversitiesOrInstitutes.value.some(selected => selected.id === item.id)) {
            categoryFilterUniversitiesOrInstitutes.value.push({ id: item.id, text: item.name, type: 'category' });
        }
    } else if (type === 'institute') {
        category.value = type;
        if (!categoryFilterUniversitiesOrInstitutes.value.some(selected => selected.id === item.id)) {
            categoryFilterUniversitiesOrInstitutes.value.push({ id: item.id, text: item.name, type: 'category' });
        }
    }

    // call APIs
    preventClear.value = true; // Prevent clear filters
    getCategoryList.value();
    getCategoryCourses.value();

    const router = useRouter();
    router.push(`/${category.value}`);
};
</script>

<script>
export default {
    created() {
        const userToken = useCookie('token');
        this.token = userToken.value;
    },
    beforeDestroy() {
        if (process.client) {
            window.removeEventListener('scroll', this.handleScroll);
        }
    },
    data: () => ({
        loginBox: true,
        login: true,
        logout: false,
        token: null,
        // scrollNav: false,
        shadow: false,
        hideNav: false,
        selectedCountry: [],
    }),
    computed: {
        rtl() {
            return this.$i18n.locale == 'ar';
        },

        destinations() {
            return this.$store.state.destinations.destinationsList;
        },
    },
    async mounted() {
        await this.$store.dispatch('destinations/getDestinations');
        if (process.client) {
            window.addEventListener('scroll', this.handleScroll);
        }
        if (this.token) {
            this.login = false;
        }
        this.loginBox = false;
        await this.$nextTick();
    },
    methods: {
        addCountrySearch(id, name) {
            this.$store.dispatch('searchParams/setData', { country_id: id, country_name: name });
            this.$router.push({
                path: this.localePath('/university'),
            });
        },
        handleScroll() {
            if (process.client) {
                this.shadow = window.scrollY > 50;
            }
        },
    },
    watch: {
        '$store.state.profile.token': {
            immediate: true,
            handler(newToken) {
                this.login = !Boolean(newToken);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.headerSection {
    position: relative;
    z-index: 99;

    .hideNav {
        display: none;
    }

    .arrow-icon {
        display: inline-block;
        border: solid $color-primary;
        border-width: 0 1px 1px 0;
        padding: 3px;
        transform: rotate(45deg);
    }

    .destinations {
        position: relative;

        .destinations-box {
            position: absolute;
            top: calc(100%);
            display: none;
            background-color: white;
            border: 1px solid #f0f0f0;
            box-shadow: 0px 4px 8px 0px #0000001a;
            min-width: 409px;
            border-radius: 10px;
            ul {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                padding: 16px 9px;
                gap: 10px;
                li {
                    padding: 10px;
                    border-radius: 10px;
                    cursor: pointer;
                    background-color: #fbfbfb;
                    font-size: 14px;
                    font-weight: 600;
                    .flag {
                        width: 40px;
                        height: 30px;
                        border-radius: 3px;
                        border: 1px solid #e4e4e4;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 2px;

                        :deep(svg) {
                            width: 100%;
                            height: auto;
                            max-height: 100%;
                        }
                    }
                    &:hover {
                        background-color: #ebedff;
                    }
                }
            }
            .see-all {
                background-color: transparent;
                color: $color-primary;
                font-size: 14px;
                font-weight: 700;
                text-decoration: underline !important;
                text-align: center;
                margin-bottom: 0.3rem;
            }
        }

        &:hover {
            .destinations-box {
                display: block;
            }
        }

        :deep(.p-listbox) {
            border-color: #f0f0f0;
            box-shadow: 0px 4px 8px 0px #0000001a;
            border-radius: 10px;
            min-width: 190px;
        }

        :deep(.p-listbox-list) {
            padding: 0 !important;
            width: 215px;
        }

        :deep(.p-listbox-item) {
            padding: 0 !important;
            margin: 10px;
            border-radius: 10px;
            color: $color-primary;
            position: relative;

            &:hover {
                background-color: #f0f9ff !important;
                cursor: pointer;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: -5px;
                width: 100%;
                border-bottom: 1px solid #f0f0f0;
            }

            &:last-of-type {
                &::after {
                    display: none;
                }
            }
        }

        &:hover {
            .arrow-icon {
                transform: rotate(-135deg);
                margin-top: 5px;
            }
        }
    }
    .universities,
    .institutes {
        position: relative;

        .dropdown-box {
            position: absolute;
            top: calc(100%);
            display: none;
            background-color: white;
            border: 1px solid #f0f0f0;
            box-shadow: 0px 4px 8px 0px #0000001a;
            min-width: 320px;
            border-radius: 10px;
            padding: 16px;
            .title {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #e3e3e3;
            }
            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                li {
                    padding: 10px;
                    border-radius: 10px;
                    cursor: pointer;
                    background-color: #fbfbfb;
                    font-size: 14px;
                    font-weight: 600;
                    width: 100%;
                    .flag {
                        width: 40px;
                        height: 40px;
                        border-radius: 3px;
                        border: 1px solid #e4e4e4;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 2px;
                        img {
                            width: 100%;
                        }
                    }
                    .content {
                        display: flex;
                        flex-direction: column;
                        .name {
                            font-size: 14px;
                            font-weight: 600;
                            color: #222222;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 200px;
                        }
                        .location-container {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            img {
                                width: 8px;
                            }
                            .location {
                                font-size: 12px;
                                font-weight: 400;
                                color: #686868;
                            }
                        }
                    }
                    &:hover {
                        background-color: #ebedff;
                    }
                }
            }
            .see-all {
                background-color: transparent;
                color: $color-primary;
                font-size: 14px;
                font-weight: 700;
                text-decoration: underline !important;
                text-align: center;
                margin-top: 10px;
                padding-bottom: 0;
            }
        }
        &:hover {
            .dropdown-box {
                display: block;
            }
            .arrow-icon {
                transform: rotate(-135deg);
                margin-top: 5px;
            }
        }
    }

    .social-links {
        @include dir(rtl) {
            margin-left: 10px;
        }

        @include dir(ltr) {
            margin-right: 10px;
        }
    }

    .header-hr {
        border-color: #f1f1f1;
        opacity: 1;
        margin-top: 16px;
    }

    &.scrollBar {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9999999999;
        padding: 15px 0;

        &.shadowBox {
            background-color: $color-white;
            box-shadow: 0px 4px 8px 0px #0000001a;
        }
    }

    @include media(768) {
        & {
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
            padding: 12px 0;
        }
    }

    .nav-box {
        ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 40px;

            li {
                a {
                    @include colorPrimary;
                    font-size: 16px;
                    padding: 10px 5px;
                    display: block;

                    &:hover {
                        color: $color-primary;
                        background-color: #f0f9ff;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

@include media(1400) {
    .headerSection {
        .nav-box {
            ul {
                gap: 29px;

                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@include media(1200) {
    .headerSection {
        .nav-box {
            ul {
                gap: 19px;
            }
        }
    }
}

@include media(992) {
    .headerSection {
        .nav-box,
        .profileBox-login,
        .lang-box {
            display: none;
        }

        .social-links {
            display: none !important;
        }

        .header-hr {
            display: none;
        }
    }
}

@media (max-width: 767.98px) {
    .hide-on-sm {
        display: none !important;
    }
}
</style>
