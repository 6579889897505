export const useCommonStore = defineStore('common', () => {
    const projectName = ref('b2c');
    const category = ref('university');
    const filterVisibility = ref(false);
    const loading = ref(false);
    const activeTab = ref(1);
    const sortCategoryType = ref('');
    const sortCourseType = ref('');
    const preventClear = ref(false);
    const compareActive = ref(false);
    const compareList = ref([]);

    const clearCompare = () => {
        const compareCookie = useCookie('compareList');

        compareList.value = [];
        compareActive.value = false;
        compareCookie.value = [];
    };

    return {
        activeTab,
        category,
        loading,
        filterVisibility,
        projectName,
        sortCategoryType,
        sortCourseType,
        preventClear,
        compareActive,
        compareList,
        clearCompare,
    };
});
