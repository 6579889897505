import { defineNuxtPlugin } from 'nuxt/app';
import { createApiService } from '../lib/api';

export default defineNuxtPlugin(() => {
    const api = createApiService();

    return {
        provide: {
            api,
        },
    };
});
