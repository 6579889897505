import { useAxios } from '@/composables/useAxios';

export const state = () => ({
    cities: [],
    citiesList: [],
    citiesCountryList: [],
    countryNumber: 0,
    mark: '',
});

export const actions = {
    getCities({ state }) {
        const { api } = useAxios();
        api.get(`/countries/${state.countryNumber}/cities`).then(res => {
            state.cities = res.data.data;
            state.citiesList = res.data.data.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                    query: `&city_ids[]=${item.id}`,
                };
            });
        });
    },
    async getCitiesCountries({ state }, payload) {
        if (!payload) {
            payload = '';
        } else {
            state.mark = '?';
        }
        let query = typeof payload === 'string' ? payload : payload.query;
        const { api } = useAxios();
        await api.get(`/cities${state.mark}${query}`).then(res => {
            state.cities = res.data.data;
            state.citiesCountryList = res.data.data.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                };
            });
            if (payload?.onSuccess) {
                payload.onSuccess(res.data.data);
            }
        });
    },
};
