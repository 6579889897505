<template>
    <div class="profileBox">
        <NuxtLink
            :to="`/profile/referral`"
            class="referral-container"
            v-if="!isinSideBar && featuresStore.isShowReferral"
        >
            <img src="./../../public/assets/imgs/referral.gif" alt="referral icon" />
        </NuxtLink>
        <span v-if="!isinSideBar && featuresStore.isShowReferral">
            <img src="~/assets/img/or_sympol.svg" />
        </span>
        <div
            class="notification-box"
            v-if="!isinSideBar"
            @click.stop="$store.state.functions.showNotification = !$store.state.functions.showNotification"
        >
            <div @click="testNotifcation">
                <i
                    badgeSeverity="secondary"
                    v-badge.secondary="$store.state.notification.count >= 100 ? '99+' : $store.state.notification.count"
                    class="pi pi-bell p-overlay-badge"
                    v-if="$store.state.notification.count > 0"
                />
                <i v-else class="pi pi-bell p-overlay-badge" />
            </div>
            <LayoutNotification v-if="$store.state.functions.showNotification" />
        </div>
        <span v-if="!isinSideBar">
            <img src="~/assets/img/or_sympol.svg" />
        </span>
        <div class="profile-box-content" @click.stop="$store.state.functions.profileMenu ? closeBox() : openMenu()">
            <div class="name-box">
                <div class="img-box">
                    <img
                        v-if="$store.state.profile.image"
                        :src="$store.state.profile.image"
                        alt="profile img"
                        class="img-fluid"
                    />
                    <img v-else src="@/assets/img/imagePlaceHolder.jpg" alt="profile img" class="img-fluid" />
                </div>
                <div class="text-box">
                    <h6 class="welcome">{{ $t('profile.welcome') }},</h6>
                    <h5 class="name text-truncate" style="width: 105px">
                        {{ $store.state.profile.first_name }}
                    </h5>
                </div>
            </div>
            <svg v-if="!isinSideBar" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                <path fill="currentColor" d="m12 15.4l-6-6L7.4 8l4.6 4.6L16.6 8L18 9.4l-6 6Z"></path>
            </svg>

            <div class="menu-box" v-if="$store.state.functions.profileMenu">
                <ul v-if="!isinSideBar">
                    <li>
                        {{ $t('myProfile') }} <i class="pi pi-user"></i>
                        <LocLink class="stretched-link" @click.stop="closeBox" to="/profile"></LocLink>
                    </li>
                    <li>
                        {{ $t('settings') }} <i class="pi pi-cog"></i>
                        <LocLink @click.stop="closeBox" class="stretched-link" to="/"></LocLink>
                    </li>
                    <li @click="logOut">{{ $t('logout') }} <i class="pi pi-sign-out"></i></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { useAxios } from '@/composables/useAxios';
import { useFeaturesStore } from '../../store/features.js';

export default {
    props: ['isinSideBar'],
    data() {
        return {
            featuresStore: useFeaturesStore(),
        };
    },
    async mounted() {
        const token = useCookie('token');
        if (token.value) {
            await this.$store.dispatch('profile/getProfile');
            await this.$store.dispatch('notification/getCountNumber');
            await this.$store.dispatch('notification/getNotificationsListBell');
        }
    },
    methods: {
        testNotifcation() {
            const { api } = useAxios();
            api.post('/notifications/make/read').then(res => {
                this.$store.state.notification.count = 0;
                this.$store.dispatch('notification/getNotificationsListBell');
            });
        },
        toToProfile() {
            router.push({ path: '/profile' });
        },
        closeBox() {
            this.$store.state.functions.profileMenu = false;
        },
        openMenu() {
            this.$store.state.functions.profileMenu = true;
        },
        async logOut() {
            this.$store.state.functions.profileMenu = false;
            const token = useCookie('token');
            token.value = null;
            const refreshToken = useCookie('refreshToken');
            refreshToken.value = null;
            localStorage.removeItem('email');
            const { api } = useAxios();
            await api.get('/auth/logout').then(res => {
                this.$store.state.login.profileMenu = false;
                this.$store.state.login.logout = true;
                this.$store.dispatch('profile/removeProfile');
                this.$router.push({
                    path: this.localePath('/'),
                });
            });
        },
    },
    // watch: {
    //   "localStorage.getItem('email')": {
    //     immediate: true,
    //     handler() {
    //     },
    //   },
    // },
};
</script>

<style lang="scss" scoped>
.reload-box {
    position: fixed;
    bottom: 50px;
    right: 50%;
    transform: translateX(50%);
    padding: 10px 20px;
    background-color: #333;
    border-radius: 5px;
    color: #eee;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 24px;

    button {
        padding: 10px 20px;
        border-radius: 4px;
        background-color: transparent;
        color: #fff;
        text-decoration: underline;
        border: 0;

        @include hover(0.2) {
            background-color: #dddddd23;
        }
    }
}

.profileBox {
    display: flex;
    align-items: center;
    gap: 18px;

    .referral-container {
        img {
            width: 35px;
        }
    }

    .notification-box {
        position: relative;
        // padding: 0 24px;
        cursor: pointer;

        // @include dir(ltr) {
        //   border-right: 2px solid $color-gray;
        // }
        // @include dir(rtl) {
        //   border-left: 2px solid $color-gray;
        // }
        i {
            cursor: pointer;
            font-size: 22px;
            @include colorPrimary;
        }
    }

    .profile-box-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
        cursor: pointer;

        .name-box {
            align-items: center;
            display: flex;
            gap: 4px;

            .img-box {
                img {
                    height: 38px;
                    width: 38px;
                    min-width: 38px;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }

            .text-box {
                .welcome {
                    font-size: 12px;
                    color: $color-gray-dark;
                }

                .name {
                    padding-top: 3px;
                    font-size: 14px;
                    color: $color-gray-dark-2;
                }
            }
        }
    }
}

.menu-box {
    position: absolute;
    overflow: hidden;
    max-width: 300px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    min-width: 241px;
    top: 60px;

    ul {
        li {
            padding: 20px;
            border-bottom: 1px solid #dadada;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 400;
            color: #222;
            position: relative;

            .stretched-link {
                position: absolute;
                z-index: 99;
                inset: 0;
            }

            @include hover(0.2) {
                background-color: #ababab11;
            }

            i {
                color: #ababab;
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
}

@include media(772) {
    .profile-box-content {
        img {
            width: 80px !important;
            height: 80px !important;
        }

        .welcome {
            color: #fff !important;
            margin-bottom: 8px;
        }

        .name {
            font-size: 20px !important;
            color: #fff !important;
        }

        .text-box {
            @include dir(rtl) {
                margin-right: 20px;
            }

            @include dir(ltr) {
                margin-left: 20px;
            }
        }
    }
}
</style>
<style lang="scss">
.p-badge {
    max-width: 20px;
    font-size: 10px;
    height: 20px;
    display: grid;
    place-content: center;
}

.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    background-color: #fff;
    background: #fff;
}

.p-menu {
    padding: 20px;

    .p-menu-list {
        li {
            border-bottom: 1px solid #dadada;

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }

    .p-menuitem > .p-menuitem-content {
        .p-menuitem-link {
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: 20px 4px;
        }
    }
}
</style>
