import { useAxios } from '@/composables/useAxios';
export const state = () => ({
    data: [],
    favoritesList: [],
    mark: '',
    sort: '',
    loading: true,
});

export const actions = {
    getFavorites({ state }, payload) {
        state.loading = true;
        if (!payload) {
            payload = '';
        } else {
            state.mark = '?';
        }
        const { api } = useAxios();
        api.get(`/favourite${state.mark}${payload}`).then(res => {
            state.data = res.data;
            state.favoritesList = res.data;
            state.loading = false;
        });
    },
};
