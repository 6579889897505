import { navigateTo, useCookie, useRuntimeConfig, useState, useAsyncData } from 'nuxt/app';
import { FetchOptions, FetchError, $fetch } from 'ofetch';

// Cache duration (session lifetime)
const CACHE_DURATION = 1000 * 60 * 60; // 1 hour
const NO_DATA_ERROR = 'No data received';

// Logger function
const logApiCall = (
    method: string,
    url: string,
    headers: Record<string, string>,
    response: unknown,
    error?: unknown
) => {
    try {
        console.group(`API ${method} Request to: ${url}`);
        console.log('Headers:', headers);

        if (error) {
            console.error('Error:', error);
        } else {
            console.log('Response:', response);
        }
    } catch (err) {
        console.error('Error in logging:', err);
    } finally {
        console.groupEnd();
    }
};

export type ApiError = {
    statusCode: number;
    message: string;
    errors?: Record<string, string[]>;
};

export type ApiResponse<T> = {
    data: T;
    error: ApiError | null;
};

export interface ApiOptions extends FetchOptions {
    withCache?: boolean;
}

interface CacheEntry {
    data: unknown;
    timestamp: number;
}

export const createApiService = () => {
    const config = useRuntimeConfig();
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const language = useCookie('i18n_redirected').value || 'en';
    const projectName = config.public.projectName;

    // Use Nuxt's useState for SSR-friendly caching
    const apiCache = useState<Map<string, CacheEntry>>('api-cache', () => new Map());

    let baseURL;
    if (projectName === 'B2C') {
        baseURL = config.public['baseURL'] as string;
    } else {
        const userInfo = useCookie('subagent_userInfo');
        if (userInfo && userInfo.value) {
            const subdomain = JSON.parse(userInfo.value)?.subDomain;
            if (subdomain) {
                baseURL = `https://${subdomain}.${config.public['baseURL'] as string}`;
            }
        }
        baseURL = `https://${config.public['baseURL'] as string}`;
    }

    const getHeaders = () => {
        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
            Accept: 'application/json, text/plain, */*',
            'X-Timezone': userTimezone,
            'Accept-Language': language,
        };
        const token = useCookie('token').value;
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        return headers;
    };

    const handleError = (error: FetchError): ApiResponse<null> => {
        if (error.response?.status === 401) {
            navigateTo('/auth/login');
        }
        return {
            data: null,
            error: {
                statusCode: error.response?.status || 500,
                message: error.message || 'An error occurred',
                errors: error.response?._data?.errors,
            },
        };
    };

    const getCacheKey = (method: string, url: string, body?: unknown) => {
        return `${method}:${url}${body ? ':' + JSON.stringify(body) : ''}`;
    };

    const getFromCache = <T>(cacheKey: string): T | null => {
        const cached = apiCache.value.get(cacheKey);
        if (!cached) return null;

        const now = Date.now();
        if (now - cached.timestamp > CACHE_DURATION) {
            apiCache.value.delete(cacheKey);
            return null;
        }

        return cached.data as T;
    };

    const setToCache = <T>(cacheKey: string, data: T) => {
        // Create a new Map to trigger reactivity
        const newCache = new Map(apiCache.value);
        newCache.set(cacheKey, {
            data,
            timestamp: Date.now(),
        });
        apiCache.value = newCache;
    };

    const clearCache = () => {
        apiCache.value = new Map();
    };

    return {
        async get<T>(url: string, options: ApiOptions = {}) {
            const { withCache = false, ...fetchOptions } = options;
            const headers = getHeaders();
            const cacheKey = getCacheKey('GET', url);

            try {
                if (withCache) {
                    const cachedData = getFromCache<T>(cacheKey);
                    if (cachedData) {
                        logApiCall(`GET Cache from Key: ${cacheKey}`, baseURL + url, headers, cachedData);
                        return { data: cachedData, error: null };
                    }
                }

                const { data, error } = await useAsyncData<T>(cacheKey, () =>
                    $fetch<T>(url, {
                        ...fetchOptions,
                        baseURL,
                        method: 'GET',
                        headers: headers,
                        responseType: 'json',
                    })
                );

                if (error.value) {
                    throw error.value;
                }

                if (!data.value) {
                    throw new Error(NO_DATA_ERROR);
                }

                logApiCall('GET', baseURL + url, headers, data.value);

                if (withCache) {
                    setToCache(cacheKey, data.value);
                }

                return { data: data.value, error: null };
            } catch (error) {
                return handleError(error as FetchError);
            }
        },

        async post<T, B extends Record<string, unknown>>(url: string, body: B, options: ApiOptions = {}) {
            const { ...fetchOptions } = options;
            const headers = getHeaders();

            try {
                const { data, error } = await useAsyncData<T>(`POST:${url}:${JSON.stringify(body)}`, () =>
                    $fetch<T>(url, {
                        ...fetchOptions,
                        baseURL,
                        method: 'POST',
                        body,
                        headers: headers,
                        responseType: 'json',
                    })
                );

                if (error.value) {
                    throw error.value;
                }

                if (!data.value) {
                    throw new Error(NO_DATA_ERROR);
                }

                logApiCall('POST', baseURL + url, headers, data.value);
                return { data: data.value, error: null };
            } catch (error) {
                return handleError(error as FetchError);
            }
        },

        async put<T, B extends Record<string, unknown>>(url: string, body: B, options: ApiOptions = {}) {
            const { ...fetchOptions } = options;
            const headers = getHeaders();

            try {
                const { data, error } = await useAsyncData<T>(`PUT:${url}:${JSON.stringify(body)}`, () =>
                    $fetch<T>(url, {
                        ...fetchOptions,
                        baseURL,
                        method: 'PUT',
                        body,
                        headers: headers,
                        responseType: 'json',
                    })
                );

                if (error.value) {
                    throw error.value;
                }

                if (!data.value) {
                    throw new Error(NO_DATA_ERROR);
                }

                logApiCall('PUT', baseURL + url, headers, data.value);
                return { data: data.value, error: null };
            } catch (error) {
                return handleError(error as FetchError);
            }
        },

        async delete<T>(url: string, options: ApiOptions = {}) {
            const { ...fetchOptions } = options;
            const headers = getHeaders();

            try {
                const { data, error } = await useAsyncData<T>(`DELETE:${url}`, () =>
                    $fetch<T>(url, {
                        ...fetchOptions,
                        baseURL,
                        method: 'DELETE',
                        headers: headers,
                        responseType: 'json',
                    })
                );

                if (error.value) {
                    throw error.value;
                }

                if (!data.value) {
                    throw new Error(NO_DATA_ERROR);
                }

                logApiCall('DELETE', baseURL + url, headers, data.value);
                return { data: data.value, error: null };
            } catch (error) {
                return handleError(error as FetchError);
            }
        },

        clearCache,
    };
};

// Composable to use the API service
export const useApi = () => {
    return createApiService();
};
