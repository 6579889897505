<template>
    <div class="applicationSection">
        <div class="container pm-0">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="content-box">
                        <h3 class="title">
                            {{ $t('Ourapplication') }}
                        </h3>
                        <p class="prag">
                            {{ $t('OurapplicationPrag') }}
                        </p>
                        <div class="d-flex gap-4">
                            <a href="#">
                                <img src="~/assets/img/android.svg" alt="phone.png" class="img-fluid" />
                            </a>
                            <a href=" https://apps.apple.com/us/app/acceptix/id6477440013 " target="_blank">
                                <img src="~/assets/img/apple.png" alt="phone.png" class="img-fluid"
                            /></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <div class="img-box">
                        <img src="~/assets/img/phone.png" alt="phone.png" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script></script>

<style scoped lang="scss">
.applicationSection {
    margin: 108px 0;
    z-index: 1;
    @include after() {
        background-color: $color-primary;
        top: -40px;
        left: 0;
        width: 100%;
        z-index: -1;
        height: 90%;
        @include media(992) {
            & {
                height: 100%;
                top: 0;
            }
        }
    }
    .img-box {
        @include media(992) {
            & {
                display: none;
            }
        }
    }
    .content-box {
        padding-top: 140px;
        @include media(1200) {
            & {
                padding-top: 50px;
            }
        }
        @include media(992) {
            & {
                padding: 50px 0;
            }
        }
        .title {
            color: #fff;
            font-size: 48px;
            font-weight: 700;
            line-height: 71.14px;
            @extend.min-title-font;
            // @include media(772) {
            //   & {
            //     font-size: 48px;
            //   }
            // }
        }
        .prag {
            color: #fff;
            font-size: 20px;
            padding: 16px 0 40px;
            font-weight: 400;
            @include media(772) {
                & {
                    font-size: 14px;
                }
            }
        }
    }
}
</style>
