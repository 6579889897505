<template>
    <div class="footer-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="footer-header d-flex justify-content-between">
                        <NuxtLink class="logo-container" to="/">
                            <img src="~/assets/img/logo_white.svg" alt="image" class="img-fluid" style="height: 90px" />
                        </NuxtLink>

                        <div class="legal-box">
                            <ul>
                                <li class="li">
                                    <LocLink to="/why-acceptix">{{ $t('aboutUs') }}</LocLink>
                                </li>
                                |
                                <li class="li">
                                    <LocLink to="/terms-and-conditions">
                                        {{ $t('footer.TermsConditConditions') }}</LocLink
                                    >
                                </li>
                                |
                                <li class="li">
                                    <LocLink to="/privacy-and-policy"> {{ $t('footer.Privacypolicy') }}</LocLink>
                                </li>
                            </ul>
                        </div>

                        <div class="legal-box">
                            <ul>
                                <li class="li">
                                    <a href="https://x.com/acceptixedtch?s=21&t=3hrgwimf5NKEcWLpuXSBPA" target="_blank">
                                        <img src="~/assets/img/icn_twitter_x.svg" alt="Twitter" />
                                    </a>
                                </li>
                                <li class="li">
                                    <a href="https://www.facebook.com/AcceptixEdtch?mibextid=LQQJ4d" target="_blank">
                                        <img src="~/assets/img/icn_facebook.svg" alt="Facebook" />
                                    </a>
                                </li>
                                <li class="li">
                                    <a href="https://www.tiktok.com/@acceptixedtch?_t=8m9ambQ9Sr7&_r=1" target="_blank">
                                        <img src="~/assets/img/icn_tiktok.svg" alt="Tiktok" />
                                    </a>
                                </li>
                                <li class="li">
                                    <a
                                        href="https://www.instagram.com/acceptixedtch?igsh=djFtcjVranZ5aG9l"
                                        target="_blank"
                                    >
                                        <img src="~/assets/img/icn_instagram.svg" alt="Twitter" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-content">
                        <div class="row align-items-end">
                            <div class="col-lg-7 col-md-12">
                                <h3 class="footer-title pb-3">
                                    <i class="pi pi-map-marker"></i> {{ $t('footer.Addresses') }}
                                </h3>
                                <div class="footer-adders-box">
                                    <div class="name-box d-flex gap-2">
                                        <p class="prag">
                                            <span class="footer-title">{{ $t('footer.KuwaitCity') }}:</span>
                                            <span>{{ $t('footer.KuwaitCityAddress') }}</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="footer-adders-box">
                                    <div class="name-box d-flex gap-2">
                                        <p class="prag">
                                            <span class="footer-title">{{ $t('footer.SaudiArabia') }}:</span>
                                            <span>{{ $t('footer.SaudiArabiaAddress') }}</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="footer-adders-box">
                                    <div class="name-box d-flex gap-2">
                                        <p class="prag">
                                            <span class="footer-title">{{ $t('footer.Egypt') }}:</span>
                                            <span>{{ $t('footer.EgyptAddress') }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-12">
                                <div>
                                    <div class="copyright pb-3">
                                        <ul>
                                            <li class="li d-flex justify-content-end">
                                                {{ $t('copyrights') }} &copy; {{ currentYear }} Acceptix
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const { locale } = useI18n();
const currentYear = new Date().getFullYear();
</script>

<style lang="scss" scoped>
.footer-section {
    margin-top: 60px;
    padding: 35px 0;
    background-color: $color-primary;
    position: relative;
    z-index: 1000;
    .logo-container {
        img {
            width: 116px;
        }
    }
    .copyright {
        color: #fff !important;
        font-size: 12px;
    }

    .nav-box {
        ul {
            align-items: center;
            justify-content: flex-end;
            gap: 12px;
            flex-wrap: wrap;
            display: flex;

            li {
                a {
                    color: #fff;
                    font-size: 12px;
                    font-weight: 400;
                    @extend.min-title-font;
                    @include hover(0.2) {
                        color: $color-secondary;
                    }
                }
            }
        }
    }

    .prag {
        color: $color-white;
        font-size: 14px;
        font-weight: 400;
        span {
            // font-weight: 600;
            display: inline;
        }
    }

    .footer-content {
        padding-top: 40px;
    }

    .footer-title {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
    }

    span.footer-title {
        @include dir(rtl) {
            margin-left: 10px;
        }

        @include dir(ltr) {
            margin-right: 10px;
        }
    }

    .footer-adders-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-of-type {
            padding-bottom: 0;
        }

        .phone-box {
            width: 50%;
            display: flex;
            align-items: center;
            gap: 12px;

            i {
                font-size: 20px;
                color: #fff;
            }

            .prag {
                display: flex;
                gap: 8px;

                a {
                    color: #fff;

                    @include hover(0.2) {
                        color: $color-secondary;
                    }
                }
            }
        }
    }

    .footer-button-box {
        width: fit-content;

        @include dir(rtl) {
            margin-right: auto;
        }

        @include dir(ltr) {
            margin-left: auto;
        }
    }

    .legal-box {
        display: flex;
        align-items: center;
        gap: 24px;
        color: #fff;

        @include media(772) {
            flex-direction: column;
            align-items: flex-start;
        }

        ul {
            display: flex;
            gap: 12px;

            li {
                a {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 400;

                    @include hover(0.2) {
                        color: $color-secondary;
                    }
                }
            }
        }
    }
}

@include media(992) {
    .footer-section {
        .nav-box {
            ul {
                justify-content: flex-start;
                padding-top: 34px;
            }
        }
    }

    .footer-section {
        .footer-button-box {
            width: 100%;
            padding-top: 34px;
        }
    }

    @include media(772) {
        .footer-section {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        .footer-header {
            flex-wrap: wrap;
            gap: 20px;
            font-size: 14px;

            ul {
                gap: 7px !important;
                text-align: center;
            }
            a {
                font-size: 13px !important;
            }
        }

        .footer-content {
            padding-top: 24px !important;
        }

        .copyright {
            margin-top: 20px;

            .li {
                @include dir(rtl) {
                    text-align: right !important;
                }

                @include dir(ltr) {
                    text-align: left !important;
                }
            }
        }

        .footer-adders-box {
            flex-direction: column;
            gap: 12px;
            align-items: flex-start !important;

            .name-box {
                max-width: 100% !important;
            }

            .phone-box {
                width: 100% !important;
            }
        }
    }
}
</style>
<style lang="scss">
.white-class {
    li {
        a {
            color: $color-white;

            svg {
                &,
                path {
                    fill: $color-white;
                }
            }
        }
    }
}
</style>
