<template>
    <div v-if="showAlert" class="alert-submission">
        <div class="icon-box">
            <i class="pi pi-exclamation-triangle"></i>
        </div>
        <div class="content-box">
            <h4 class="title">{{ title }}</h4>
            <p class="prag">{{ content }}</p>
        </div>
        <div class="close-item" @click="showAlert = false">
            <i class="pi pi-times"></i>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'content'],
    data() {
        return {
            showAlert: true,
        };
    },
};
</script>
