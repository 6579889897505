export const state = () => ({
    data: {},
});

export const actions = {
    setData({ state }, payload) {
        state.data = payload;
    },
    clearData({ state }) {
        state.data = {};
    },
};
