import { useAxios } from '@/composables/useAxios';
import axios from 'axios';

export const state = () => ({
    universityCourses: [],
    universityCoursesList: [],
    universityCoursesListDestination: [],
    getCourseDestinationList: [],
    countryNumber: 0,
    loading: false,
    result: [],
    resultCourseName: [],
    keyWord: '',
    keyWordCourse: '',
    keyWordCourseQuery: '',
    searchWord: '',
    query: '',
    order: '',
    per_page: 9,

    cancelTokenSource: null,
    coursesPage: 1,
    coursesTotalPage: 10,
    coursesLoading: false,
    coursesSearch: '',
});

export const actions = {
    getUniversityCourses({ state }) {
        const { api } = useAxios();
        api.get(`/university-courses`).then(res => {
            state.universityCourses = res.data.data;
            state.universityCoursesList = res.data.data.map(item => {
                return { id: item.id, name: item.name };
            });
        });
    },

    async getUniversityCoursesDestination({ state }) {
        if (state.coursesPage < state.coursesTotalPage && !state.coursesLoading) {
            state.coursesLoading = true;

            // Cancel the previous request if it exists
            if (state.cancelTokenSource) {
                state.cancelTokenSource.cancel('Request canceled due to new request.');
            }

            // Create a new cancel token source
            state.cancelTokenSource = axios.CancelToken.source();

            let query = '';
            if (state.getCourseDestinationList.length) {
                query = `${state.getCourseDestinationList}`;
            }
            if (state.coursesSearch) {
                query += `&course_name_keyword=${state.coursesSearch}`;
            }
            const { api } = useAxios();
            await api
                .get(`/university-courses?per_page=15&page=${state.coursesPage}&sort=1&${query}`, {
                    cancelToken: state.cancelTokenSource.token,
                })
                .then(res => {
                    state.coursesLoading = false;
                    state.coursesPage += 1;
                    state.universityCoursesListDestination.push(
                        ...res.data.data.map(item => {
                            return { id: item.id, name: item.name };
                        })
                    );

                    if (res.data?.meta) {
                        state.coursesTotalPage = res.data.meta.total;
                    }
                });
        }
    },

    async resetUniversityCoursesDestination({ state }, searchInput) {
        state.coursesPage = 1;
        state.coursesTotalPage = 10;
        state.coursesLoading = false;
        state.universityCoursesListDestination = [];
        state.coursesSearch = searchInput;
    },

    async getUniversitiesListSearch({ state }, payload) {
        if (state.keyWordCourse) {
            state.keyWordCourseQuery = `&course_name_keyword=${state.keyWordCourse}`;
        } else {
            state.keyWordCourseQuery = '';
        }
        if (payload.keyWord) {
            state.keyWord = payload.keyWord;
        } else {
            state.keyWord = '';
        }
        if (payload.query) {
            state.query = payload.query;
        } else {
            state.query = '';
        }
        state.loading = true;
        const { api } = useAxios();
        await api
            .get(
                `/university-courses?university_ids[]=${state.keyWord}${state.searchWord}&per_page=${state.per_page}${state.query}&${state.order}${state.keyWordCourseQuery}`
            )
            .then(res => {
                state.loading = false;
                state.data = res.data.data;
                state.result = [
                    ...state.data.map(item => ({
                        id: item.id,
                        type: item.type,
                        name: item.name,
                        offer: item.fees,
                        offerCount: item.sale_percentage + '%',
                        price: item.fees_after_sale,
                        has_sale: item.has_sale,
                        location: item.location,
                        image: item.image,
                        favourite: item.favourite,
                        course_type: item.course_type,
                        currency: item.currency,
                    })),
                ];
                state.resultList = [
                    ...state.data.map(item => ({
                        id: item.id,
                        name: item.name,
                        offer: item.fees,
                        query: `&university_course_ids[]=${item.id}`,
                    })),
                ];
            })
            .catch(() => {
                state.loading = false;
            });
    },
    async getUniversitiesListSearchCourseName({ state }, payload) {
        if (state.keyWordCourse) {
            state.keyWordCourseQuery = `&course_name_keyword=${state.keyWordCourse}`;
        } else {
            state.keyWordCourseQuery = '';
        }
        if (payload.keyWord) {
            state.keyWord = payload.keyWord;
        } else {
            state.keyWord = '';
        }
        if (payload.query) {
            state.query = payload.query;
        } else {
            state.query = '';
        }
        const { api } = useAxios();
        await api
            .get(
                `/university-courses?university_ids[]=${state.keyWord}${state.searchWord}&per_page=400${state.query}${state.keyWordCourseQuery}`
            )
            .then(res => {
                state.loading = false;
                state.data = res.data.data;
                state.resultCourseName = [
                    ...state.data.map(item => ({
                        id: item.id,
                        name: item.name,
                        offer: item.fees,
                        query: `&university_course_ids[]=${item.id}`,
                    })),
                ];
            })
            .catch(() => {
                state.loading = false;
            });
    },
};
