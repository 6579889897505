<template>
    <div class="suggested-subjects">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="title">{{ $t('suggestedSubjects') }}</h2>
                    <p class="brief">{{ $t('suggestedSubjectsBrief') }}</p>
                </div>
            </div>
            <div v-if="!subjects.length" class="row">
                <div class="col-md-12">
                    <Skeleton class="mx-2 mx-auto" height="15rem" width="100%"></Skeleton>
                </div>
            </div>
            <div v-else class="row">
                <div class="suggested-subjects-section col-md-12">
                    <Swiper
                        :autoplay="{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }"
                        :breakpoints="{
                            '0': {
                                slidesPerView: 1.3,
                                navigation: {
                                    enabled: false,
                                    hiddenClass: 'd-none',
                                    disabledClass: 'd-none',
                                },
                            },
                            '500': {
                                slidesPerView: 2,
                                navigation: {
                                    enabled: false,
                                    hiddenClass: 'd-none',
                                    disabledClass: 'd-none',
                                },
                            },
                            '993': {
                                slidesPerView: 2,
                                navigation: {
                                    enabled: false,
                                    hiddenClass: 'd-none',
                                    disabledClass: 'd-none',
                                },
                            },
                            '1024': {
                                slidesPerView: 3,
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            },
                        }"
                        :dir="rtl"
                        :loop="true"
                        :modules="[SwiperAutoplay, SwiperNavigation]"
                        :pagination="{ clickable: true }"
                        :parallax="true"
                        :slidesOffsetAfter="30"
                        :slidesOffsetBefore="30"
                        class="h-100"
                        navigation
                        spaceBetween="0"
                    >
                        <swiper-slide v-for="(subject, index) in subjects" :key="index" class="h-auto">
                            <div class="subject-card">
                                <div class="cube"></div>
                                <h3>{{ subject.title }}</h3>
                                <p>{{ subject.description }}</p>
                                <div class="link-container">
                                    <LocLink :to="`/subject/${subject.id}`" class="link">
                                        {{ $t('Viewdetails') }}
                                    </LocLink>
                                </div>
                            </div>
                        </swiper-slide>
                        <!-- <div class="swiper-button-next">
                            <i class="pi pi-chevron-right" style="color: #bdbdbd" v-if="locale == 'en'"></i>
                            <i class="pi pi-chevron-left" style="color: #bdbdbd" v-else></i>
                        </div>

                        <div class="swiper-button-prev">
                            <i class="pi pi-chevron-left" style="color: #bdbdbd" v-if="locale == 'en'"></i>
                            <i class="pi pi-chevron-right" style="color: #bdbdbd" v-else></i>
                        </div> -->
                    </Swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const { locale } = useI18n();
</script>

<script>
import { useAxios } from '@/composables/useAxios';

export default {
    async mounted() {
        const { api } = useAxios();
        api.get(`/subjects`, {
            headers: {
                'Accept-Language': this.$i18n.locale,
            },
        }).then(res => {
            this.subjects = res?.data?.data.map(ele => ({ id: ele.id, title: ele.name, description: ele.description }));
        });
    },
    data() {
        return {
            subjects: [],
        };
    },
    computed: {
        rtl() {
            return this.$i18n.locale == 'ar';
        },
    },
};
</script>

<style lang="scss">
.suggested-subjects {
    .swiper .swiper-pagination {
        bottom: -60px;
    }
}
</style>

<style lang="scss">
.swiper-button-next {
    right: 0;

    &:after {
        color: $color-secondary;
    }

    @include dir(rtl) {
        left: 0;
        right: unset;
    }
}

.swiper-button-prev {
    left: 0;

    &:after {
        color: $color-secondary;
    }

    @include dir(rtl) {
        right: 0;
        left: unset;
    }
}

// .swiper-button-prev,
// .swiper-button-next {
//     height: 99%;
//     top: 50%;
//     padding: 2px;
//     width: 56px;
//     transform: translateY(-43%);
//     background-color: #fff;

//     &:after {
//         // display: none;
//     }

//     i {
//         height: 37px;
//         min-width: 37px;
//         background-color: #fff;
//         border-radius: 50%;
//         box-shadow: 1px 2px 10px #33333350;
//         display: grid;
//         place-content: center;
//     }

//     .pi {
//         font-size: 22px;
//     }
// }

@include media(772) {
    .suggested-subjects-section {
        .swiper {
            padding: 0;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }
}

.suggested-subjects {
    margin-bottom: 3rem;

    .title {
        font-family: 'Oswald', sans-serif;
        color: #1f299c;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 40px;
    }

    .brief {
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        margin-bottom: 40px;
        font-family: 'Mulish', sans-serif;
    }

    .suggested-subjects-section {
        margin: 2rem 0;
        height: 100%;

        .subject-card {
            margin: 0 1.5rem;
            width: 80%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .cube {
                height: 30px;
                width: 30px;
                background-color: #1f299c;
                margin-bottom: 14px;
            }

            h3 {
                font-size: 20px;
                font-weight: 500;
                font-family: 'Oswald', sans-serif;
                color: #000000cc;
                margin-bottom: 14px;
            }

            p {
                font-family: 'Mulish', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 31px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 6;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 16px;
                flex-grow: 1;
            }

            .link-container {
                justify-content: flex-end;
                align-items: end;

                .link {
                    font-family: 'Mulish', sans-serif;
                    font-weight: 800;
                    font-size: 16px;
                    color: #1f299c;
                    display: inline-block;
                }
            }
        }
    }
}

@media (min-width: 772px) {
    .suggested-subjects {
        .title {
            font-size: 64px;
        }

        .brief {
            font-size: 20px;
        }

        .suggested-subjects-section {
            .subject-card {
                .cube {
                    height: 30px;
                    width: 30px;
                }

                h3 {
                    font-size: 36px;
                }

                p {
                    font-size: 20px;
                    line-height: 38px;
                }

                .link-container {
                    .link {
                        font-family: 'Mulish', sans-serif;
                        font-weight: 800;
                        font-size: 16px;
                        color: #1f299c;
                        display: inline-block;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .suggested-subjects-section {
        .subject-card {
            padding: 20px;
            border: 1px solid #f1f1f1;
            box-shadow: 0px 4px 10px 0px #00000026;
            border-radius: 10px;
        }
    }
}
</style>
