<template>
    <ul class="sochel-media" :class="{ whiteItem: whiteItem }">
        <li class="li">
            <a href="https://x.com/acceptixedtch?s=21&t=3hrgwimf5NKEcWLpuXSBPA" target="_blank">
                <img v-if="whiteItem" src="~/assets/img/icn_twitter_x.svg" alt="Twitter" />
                <img v-else src="~/assets/img/icn_twitter_x_blue.svg" alt="Twitter" />
            </a>
        </li>
        <li class="li">
            <a href="https://www.facebook.com/AcceptixEdtch?mibextid=LQQJ4d" target="_blank">
                <img v-if="whiteItem" src="~/assets/img/icn_facebook.svg" alt="Facebook" />
                <img v-else src="~/assets/img/icn_facebook_blue.svg" alt="Facebook" />
            </a>
        </li>
        <li class="li">
            <a href="https://www.tiktok.com/@acceptixedtch?_t=8m9ambQ9Sr7&_r=1" target="_blank">
                <img v-if="whiteItem" src="~/assets/img/icn_tiktok.svg" alt="Tiktok" />
                <img v-else src="~/assets/img/icn_tiktok_blue.svg" alt="Tiktok" />
            </a>
        </li>
        <li class="li">
            <a href="https://www.instagram.com/acceptixedtch?igsh=djFtcjVranZ5aG9l" target="_blank">
                <img v-if="whiteItem" src="~/assets/img/icn_instagram.svg" alt="Twitter" />
                <img v-else src="~/assets/img/icn_instagram_blue.svg" alt="Twitter" />
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        whiteItem: {
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.sochel-media {
    display: flex;
    gap: 12px;
    &.whiteItem {
        li {
            a {
                color: #fff;
                svg {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
    li {
        a {
            @include colorPrimary;
            svg {
                height: 18px;
                width: 18px;
            }
        }
    }
}
</style>
