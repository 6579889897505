<template>
    <Toast />
    <div class="JourneySection">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <GeneralMinTitle :title="$t('JourneySectionTitle')" />
                </div>
            </div>
            <div class="row">
                <div class="col-xxl-4 col-lg-4 col-md-6 mb-4">
                    <div class="JourneyBox">
                        <div class="img-box">
                            <img src="~/assets/img/student.jpg" alt="" class="img-fluid" />
                        </div>
                        <h4 class="name">{{ $t('Student') }}</h4>
                        <p class="prag">
                            {{ $t('JourneySectionBoxPrag1') }}
                        </p>
                        <div class="button-box">
                            <Button style="width: 70%" @click="registButton" :label="$t('JoinNow')" />
                        </div>
                    </div>
                </div>
                <div class="col-xxl-4 col-lg-4 col-md-6 mb-4">
                    <div class="JourneyBox">
                        <div class="img-box">
                            <img src="~/assets/img/partnershipForm.webp" alt="" class="img-fluid" />
                        </div>
                        <h4 class="name">{{ $t('ambassdor') }}</h4>
                        <p class="prag">
                            {{ $t('JourneySectionBoxPrag2') }}
                        </p>
                        <div class="button-box">
                            <Button style="width: 70%" @click="openVisible" :label="$t('ConnectWithUs')" />
                        </div>
                    </div>
                </div>
                <div class="col-xxl-4 col-lg-4 col-md-6 mb-4">
                    <div class="JourneyBox">
                        <div class="img-box">
                            <img src="~/assets/img/subA.webp" alt="" class="img-fluid" />
                        </div>
                        <h4 class="name">{{ $t('Institutepartnership') }}</h4>
                        <p class="prag">
                            {{ $t('JourneySectionBoxPrag3') }}
                        </p>
                        <div class="button-box">
                            <Button
                                style="width: 70%"
                                @click="openBecomeAmbassadorDialog"
                                :label="$t('PartnerWithUs')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog
        v-model:visible="visible"
        :header="$t('BecomeAnAmbassador')"
        :style="{ width: '32rem' }"
        position="right"
        :modal="true"
        dismissableMask
        :draggable="false"
        class="journey-sidebar"
    >
        <div class="img-box" v-if="!showDoneAmbassador">
            <div class="form-box pt-2">
                <Form :validation-schema="schema" @submit="ambassadorForm">
                    <div class="pb-3">
                        <p class="pleasePrag">{{ $t('PleasePrag') }}</p>
                    </div>
                    <div class="pb-4">
                        <div class="full-box">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <UtilsFormInputText
                                        id="first_name"
                                        :placeholder="$t('profile.first_name')"
                                        name="first_name"
                                    />
                                </div>
                                <div class="col-12 col-lg-6">
                                    <UtilsFormInputText
                                        id="last_name"
                                        :placeholder="$t('profile.last_name')"
                                        name="last_name"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pb-4">
                        <div class="full-box">
                            <UtilsFormInputText id="email" :placeholder="$t('profile.email')" name="email" />
                        </div>
                    </div>
                    <div class="pb-4">
                        <UtilsFormPhoneNumber phoneName="phone" option-label="code" required />
                    </div>
                    <div class="pb-4">
                        <div class="full-box">
                            <UtilsFormInputText
                                id="organization_name"
                                :placeholder="$t('Organization')"
                                name="organization_name"
                            />
                        </div>
                    </div>
                    <div class="pb-4">
                        <UtilsFormCountrySelect
                            id="country_of_residence_id"
                            :placeholder="$t('Countryofresidence')"
                            name="country_of_residence_id"
                        />
                    </div>
                    <div class="submit-container text-center">
                        <Button type="submit" :label="$t('Sendrequest')" class="mt-4 button-send" />
                    </div>
                </Form>
            </div>
        </div>
        <DoneScreen v-else :fullWidth="true" :buttonBox="false" :message="$t('DoneAmbassadorRequest')" />
    </Dialog>

    <Dialog
        position="right"
        v-model:visible="becomeAmbassadorDialog"
        :header="$t('Institutepartnership')"
        :style="{ width: '32rem' }"
        :modal="true"
        dismissableMask
        :draggable="false"
        class="journey-sidebar"
    >
        <div class="form-box pt-2" v-if="!showDonePartnership">
            <Form @submit="partnershipForm" :validation-schema="partnershipSchema">
                <div class="pb-3">
                    <p class="pleasePrag">{{ $t('PleasePrag') }}</p>
                </div>

                <!--country code-->
                <div class="pb-4">
                    <UtilsFormCountrySelect id="country_id" :placeholder="$t('country')" name="country_id" />
                </div>

                <!-- School Name -->
                <div class="pb-4">
                    <div class="full-box">
                        <UtilsFormInputText id="school_name" :placeholder="$t('schoolName')" name="school_name" />
                    </div>
                </div>

                <!-- Contact First Name -->
                <div class="pb-4">
                    <div class="full-box">
                        <UtilsFormInputText id="first_name" :placeholder="$t('profile.first_name')" name="first_name" />
                    </div>
                </div>

                <!-- Contact Last Name -->
                <div class="pb-4">
                    <div class="full-box">
                        <UtilsFormInputText id="last_name" :placeholder="$t('profile.last_name')" name="last_name" />
                    </div>
                </div>

                <!-- Contact Email -->
                <div class="pb-4">
                    <div class="full-box">
                        <UtilsFormInputText id="email" :placeholder="$t('profile.email')" name="email" />
                    </div>
                </div>

                <!-- Phone Number -->
                <div class="pb-4">
                    <UtilsFormPhoneNumber phoneName="phone" option-label="code" required />
                </div>

                <div class="text-center">
                    <Button type="submit" :label="$t('Sendrequest')" class="mt-4 button-send" />
                </div>
            </Form>
        </div>
        <DoneScreen v-else :fullWidth="true" :buttonBox="false" :message="$t('DonePartnershipRequest')" />
    </Dialog>

    <Dialog
        position="right"
        v-model:visible="registerDialog"
        :header="$t('Register')"
        :style="{ width: '32rem' }"
        :modal="true"
        dismissableMask
        :draggable="false"
        class="journey-sidebar"
    >
        <div class="img-container d-flex flex-wrap justify-content-center">
            <h2 class="text-center pt-2 pb-2">{{ $t('alreadyAcceptixFamily') }}</h2>
            <p class="text-center mt-3 text-default">{{ $t('youAreLoggedIn') }}</p>
        </div>
    </Dialog>
</template>

<script>
import { useAxios } from '@/composables/useAxios';
import { Form } from 'vee-validate';
import * as yup from 'yup';

export default {
    name: 'JourneySection',
    components: {
        Form,
    },
    async mounted() {
        const token = useCookie('token');
        if (token.value) {
            this.loggedIn = true;
        }
    },
    computed: {
        schema() {
            return yup.object({
                first_name: yup
                    .string()
                    .matches(this.nameRegex, {
                        message: this.nameValidationError,
                        excludeEmptyString: true,
                    })
                    .trim()
                    .required(this.firstNameErrorMessage),
                last_name: yup
                    .string()
                    .matches(this.nameRegex, {
                        message: this.nameValidationError,
                        excludeEmptyString: true,
                    })
                    .trim()
                    .required(this.lastNameErrorMessage),
                email: yup.string().email(this.emailValidationError).required(this.emailValidationError),
                country_code: yup.object().required(this.countryCodeValidationError),
                phone: yup
                    .string()
                    .matches(/^[0-9]+$/, { message: this.phoneNumberValidationError })
                    .transform(value => (Number.isNaN(value) ? null : value))
                    .required(this.phoneNumberRequiredError),

                organization_name: yup
                    .string()
                    .matches(this.nameRegex, {
                        message: this.nameValidationError,
                        excludeEmptyString: true,
                    })
                    .trim()
                    .required(this.organizationNameErrorMessage),
                country_of_residence_id: yup.object().required(this.countryOfResidenceValidationError),
            });
        },
        partnershipSchema() {
            return yup.object({
                first_name: yup
                    .string()
                    .matches(this.nameRegex, {
                        message: this.nameValidationError,
                        excludeEmptyString: true,
                    })
                    .trim()
                    .required(this.firstNameErrorMessage),
                last_name: yup
                    .string()
                    .matches(this.nameRegex, {
                        message: this.nameValidationError,
                        excludeEmptyString: true,
                    })
                    .trim()
                    .required(this.lastNameErrorMessage),
                email: yup.string().email(this.emailValidationError).required(this.emailValidationError),
                country_code: yup.object().required(this.countryCodeValidationError),
                phone: yup
                    .string()
                    .matches(/^[0-9]+$/, { message: this.phoneNumberValidationError })
                    .transform(value => (Number.isNaN(value) ? null : value))
                    .required(this.phoneNumberRequiredError),

                school_name: yup
                    .string()
                    .matches(this.nameRegex, {
                        message: this.nameValidationError,
                        excludeEmptyString: true,
                    })
                    .trim()
                    .required(this.organizationNameErrorMessage),
                country_id: yup.object().required(this.countryValidationError),
            });
        },
    },
    data() {
        return {
            visible: false,
            registerDialog: false,
            becomeAmbassadorDialog: false,
            loggedIn: false,
            showDoneAmbassador: false,
            showDonePartnership: false,
            nameRegex: /^(?![\d])[\w@\-#_.\u0600-\u06FF\s]{3,20}$/,
            nameValidationError: this.$t('inputValidationError'),
            firstNameErrorMessage: this.capitalizeWords(this.$t('fisrtNameError')),
            lastNameErrorMessage: this.capitalizeWords(this.$t('lastNameError')),
            emailValidationError: this.capitalizeWords(this.$t('EmailValidation')),
            organizationNameErrorMessage: this.capitalizeWords(this.$t('organizationValidation')),
            countryOfResidenceValidationError: this.capitalizeWords(this.$t('Pleaseenteryourcountry')),
            countryCodeValidationError: this.capitalizeWords(this.$t('theselected')),
            phoneNumberRequiredError: this.capitalizeWords(this.$t('PleaseenteryourphoneNumber')),
            phoneNumberValidationError: this.capitalizeWords(this.$t('MobileValidation')),
            countryValidationError: this.capitalizeWords(this.$t('Pleaseenteryourcountry')),
        };
    },
    methods: {
        capitalizeWords(str) {
            return str.replace(/\b\w/g, match => match.toUpperCase());
        },

        openBecomeAmbassadorDialog() {
            this.becomeAmbassadorDialog = true;
            this.showDoneAmbassador = false;
            this.showDonePartnership = false;
        },
        openVisible() {
            this.visible = true;
            this.showDoneAmbassador = false;
            this.showDonePartnership = false;
        },
        registButton() {
            const token = useCookie('token');
            if (token.value) {
                this.$toast.add({
                    summary: this.$t('alreadyAcceptixFamily'),
                    severity: 'success',
                    detail: this.$t('youAreLoggedIn'),
                    life: 3000,
                });
            } else {
                this.$router.push(this.localePath('/auth/signup'));
            }
        },

        ambassadorForm(values, actions) {
            values.country_of_residence_id = values.country_of_residence_id.id;
            values.country_code = values.country_code.code;
            values.phone = values.phone.toString();

            const { api } = useAxios();
            api.post('/ambassadors', values)
                .then(res => {
                    this.showDoneAmbassador = true;
                })
                .catch(function (error) {
                    actions.setErrors(error.response.data.errors);
                    const first_error = Object.keys(error.response.data.errors)[0];
                    document.getElementsByName(first_error)[0].scrollIntoView();
                });
        },
        partnershipForm(values, actions) {
            values.country_id = values.country_id.id;
            values.country_code = values.country_code.code;
            values.phone = values.phone.toString();
            const { api } = useAxios();
            api.post('/partnerships', values)
                .then(res => {
                    this.showDonePartnership = true;
                })
                .catch(function (error) {
                    actions.setErrors(error.response.data.errors);
                    const first_error = Object.keys(error.response.data.errors)[0];
                    document.getElementsByName(first_error)[0].scrollIntoView();
                });
        },
    },
};
</script>

<style lang="scss">
.p-dialog-mask {
    z-index: 110199999999 !important;
}

.journey-sidebar {
    min-height: 100vh !important;
    max-height: 100vh !important;
    height: 100vh;
    margin: 0;

    .p-dialog-content {
        height: 100%;

        .img-box {
            height: 100%;

            .form-box {
                height: 100%;

                form {
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    .submit-container {
                        margin-top: auto;
                    }
                }
            }
        }
    }

    .img-box {
        img {
            border-radius: 10px;
            max-height: 390px;
            object-fit: cover;
        }
    }

    .img-container {
        min-height: 40vh;

        hr {
            margin-top: 0;
            margin-bottom: 25px;
        }

        img {
            border-radius: 10px;
            max-height: 390px;
            object-fit: cover;
        }
    }

    h2 {
        color: $color-primary;
        font-size: 32px;
    }

    .button-send {
        width: 250px !important;
    }

    @include media(772) {
        margin-top: 20px;

        min-height: calc(100vh - 20px) !important;
        max-height: calc(100vh - 20px) !important;

        @include dir(rtl) {
            margin-left: 20px;
        }

        @include dir(ltr) {
            margin-right: 20px;
        }
    }
}
</style>
<style lang="scss" scoped>
.pleasePrag {
    color: $color-primary;

    font-size: 14px;
    font-weight: 400;
}

.button-send {
    width: 50%;

    @include media(772) {
        width: 100%;
    }
}

.JourneySection {
    margin-top: 60px;
}

.JourneyBox {
    padding: 40px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 10px 0px #00000026;
    text-align: center;
    border-radius: 10px;
    min-height: 670px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .img-box {
        img {
            width: 219px;
            height: 231px;
            border-radius: 10px;
            object-fit: cover;
        }
    }

    .name {
        padding: 28px;

        @extend .min-title-font;
        font-size: 32px;
        font-weight: 700;
        color: $color-primary;
    }

    .prag {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: #222222;
        padding-bottom: 40px;
    }

    .button-box {
    }
}
</style>
