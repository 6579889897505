<template>
    <div class="input-box" v-tooltip.hover.top="computedTooltip">
        <Dropdown
            ref="dropdown"
            v-model="internalValue"
            :appendTo="isMobile ? 'body' : appendTo"
            :class="[{ 'p-invalid': error }]"
            :disabled="disabled"
            :emptyFilterMessage="computedEmptyFilterMessage"
            :emptyMessage="computedEmptyMessage"
            :filterFields="filterFields"
            :filterPlaceholder="computedFilterPlaceholder"
            :loading="computedLoading"
            :onFilter="onFilter"
            :optionLabel="optionLabel"
            :optionValue="optionValue"
            :options="options"
            :placeholder="computedPlaceholder"
            :required="required"
            :showClear="showClear"
            :virtualScrollerOptions="virtualScrollerOptions"
            class="w-100"
            filter
            filterIcon="pi pi-search"
        >
            <template #option="slotProps">
                <div class="d-flex align-items-center gap-1">
                    <div
                        v-if="slotProps.option?.show?.includes('svg')"
                        class="phone-country-flag"
                        v-html="slotProps.option.show"
                    ></div>
                    <div v-else-if="slotProps.option?.flag" class="phone-country-flag">
                        <div v-html="slotProps.option.flag"></div>
                    </div>
                    <div v-else-if="slotProps.option?.show?.includes('http')" class="phone-country-flag">
                        <img :src="slotProps.option.show" alt="Image" />
                    </div>
                    <div v-else-if="slotProps.option?.image" class="phone-country-flag">
                        <img :src="slotProps.option.image" alt="Image" />
                    </div>
                    <div :class="computedClass" :style="computedStyle" :title="slotProps.option.name">
                        {{ slotProps.option.name }}
                    </div>
                </div>
            </template>

            <template #value="slotProps">
                <div v-if="hasValue(internalValue)" class="d-flex align-items-center gap-1 mx-1">
                    <div v-if="optionLabel" :class="computedClass" :style="computedStyle">
                        <div v-if="selectedOption" class="d-flex align-items-center">
                            <div v-if="optionLabel === 'code'" class="d-flex align-items-center gap-1">
                                <div v-if="flag?.includes('svg')" class="phone-country-flag" v-html="flag"></div>
                                <div v-else-if="flag?.includes('http')" class="phone-country-flag">
                                    <img :src="flag" alt="Image" />
                                </div>

                                <span> +</span>
                            </div>
                            <span>{{ selectedOption }}</span>
                        </div>
                        <span v-else class="mx-1">
                            {{ slotProps.placeholder }}
                        </span>
                    </div>
                    <div v-else class="d-flex align-items-center gap-1">
                        <div
                            v-if="slotProps.value?.show"
                            class="phone-country-flag"
                            v-html="slotProps.value.show"
                        ></div>
                        <div :class="computedClass" :style="computedStyle">
                            {{ slotProps.value.name }}
                        </div>
                    </div>
                </div>
                <span v-else class="mx-1">
                    {{ slotProps.placeholder }}
                </span>
            </template>

            <template #empty>
                <div v-if="onEmpty">
                    {{ onEmpty }}
                </div>
                <div v-else class="spinner-container d-flex justify-content-center align-items-center h-54px">
                    <ProgressSpinner
                        animationDuration=".5s"
                        aria-label="Custom ProgressSpinner"
                        fill="#fff"
                        strokeWidth="2"
                        style="width: 30px; height: 30px"
                    />
                </div>
            </template>
        </Dropdown>

        <InputComponentsInputMessage v-if="error" class="text-start">
            {{ errorMessage }}
        </InputComponentsInputMessage>
    </div>
</template>

<script>
export default {
    emits: ['update:modelValue', 'change'],

    props: {
        required: {
            type: Boolean,
            default: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        error: {
            type: Boolean,
            default: false,
        },

        errorMessage: {
            type: String,
            default: '',
        },

        placeholder: {
            type: String,
            default: '',
        },

        emptyFilterMessage: {
            type: String,
            default: '',
        },

        emptyMessage: {
            type: String,
            default: '',
        },

        filterFields: {
            type: Array,
            default: () => ['name'],
        },

        filterPlaceholder: {
            type: String,
            default: '',
        },

        options: {
            type: Array,
            required: true,
            default: () => [],
        },

        virtualScrollerOptions: {
            type: Object,
            default: null,
        },

        onFilter: {
            type: Function,
            default: null,
        },

        closeOnScroll: {
            type: Boolean,
            default: false,
        },

        appendTo: {
            type: String,
            default: 'self',
        },

        modelValue: {
            type: [Number, String, Object],
            default: null,
        },

        showClear: {
            type: Boolean,
            default: false,
        },

        optionLabel: {
            type: String,
            default: null,
        },

        optionValue: {
            type: String,
            default: null,
        },

        onEmpty: {
            type: String,
            default: null,
        },

        textTruncateWidth: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            internalValue: null,
            localizedDefaults: {},
            initialScrollTop: null,
            isMobile: false,
        };
    },

    computed: {
        computedPlaceholder() {
            return this.placeholder || this.localizedDefaults.placeholder;
        },

        computedEmptyFilterMessage() {
            return this.emptyFilterMessage || this.localizedDefaults.emptyFilterMessage;
        },

        computedEmptyMessage() {
            return this.emptyMessage || this.localizedDefaults.emptyMessage;
        },

        computedFilterPlaceholder() {
            return this.filterPlaceholder || this.localizedDefaults.filterPlaceholder;
        },

        computedLoading() {
            return this.loading && this.options.length === 0;
        },

        selectedOption() {
            if (this.internalValue) {
                let item;
                if (this.optionValue) {
                    item = this.options.find(item => item?.[this.optionValue] === this.internalValue);
                } else {
                    item = this.options.find(item => item?.id === this.internalValue.id);
                }
                if (this.optionLabel) {
                    return item?.[this.optionLabel] ?? '';
                } else {
                    return item?.name ?? '';
                }
            }
            return '';
        },

        computedClass() {
            return { 'text-truncate': Boolean(this.textTruncateWidth) };
        },

        computedStyle() {
            return {
                width: Boolean(this.textTruncateWidth) ? this.textTruncateWidth : 'auto',
            };
        },

        flag() {
            let item;
            if (this.optionValue) {
                item = this.options.find(item => item?.[this.optionValue] === this.internalValue);
            } else {
                item = this.options.find(item => item?.id === this.internalValue.id);
            }
            return item?.show ?? item?.flag ?? item?.emoji ?? '';
        },

        computedTooltip() {
            if (this.textTruncateWidth && this.internalValue) {
                if (typeof this.internalValue === 'object') {
                    return this.internalValue?.[this.optionLabel] ?? this.internalValue?.name ?? '';
                }
            }
            return '';
        },
    },

    watch: {
        modelValue(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.setValue(newVal);
            }
        },

        internalValue(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                if (
                    this.optionValue &&
                    newVal?.[this.optionValue] &&
                    oldVal?.[this.optionValue] &&
                    newVal?.[this.optionValue] === oldVal?.[this.optionValue]
                ) {
                    return;
                } else if (newVal?.id && oldVal?.id && newVal?.id === oldVal?.id) {
                    return;
                }
                this.$emit('update:modelValue', newVal);
                this.$emit('change', newVal);
            }
        },

        options() {
            this.setValue(this.modelValue);
        },
    },

    methods: {
        setValue(value) {
            if (typeof value === 'object' && value?.id) {
                let item = this.options.find(item => item?.id === value?.id);
                if (item) {
                    this.internalValue = item;
                } else {
                    this.internalValue = value;
                }
            } else {
                this.internalValue = value;
            }
        },

        handleScroll() {
            const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
            if (this.initialScrollTop === null) {
                this.initialScrollTop = currentScrollTop;
            }
            const scrollDistance = Math.abs(currentScrollTop - this.initialScrollTop);
            if (scrollDistance > 400) {
                this.closeDropdownOnScroll();
            }
        },

        closeDropdownOnScroll() {
            const dropdown = this.$refs.dropdown;
            if (dropdown && dropdown.overlayVisible) {
                dropdown.overlayVisible = false;
            }
        },

        clear() {
            this.internalValue = null;
        },

        hasValue(value) {
            if (value == null) return false; // Check for null or undefined

            if (typeof value === 'object') {
                if (Array.isArray(value)) {
                    // Check for empty array
                    return value.length > 0;
                } else {
                    // Check for empty object and that all keys have values
                    return Object.keys(value).length > 0;
                }
            }

            // Check for other falsy values (like empty string)
            return value !== '';
        },

        detectMobile() {
            this.isMobile = window.innerWidth < 576;
        },
    },

    created() {
        this.localizedDefaults = {
            placeholder: '',
            emptyFilterMessage: this.$t('Noresultsfound'),
            emptyMessage: this.$t('Noresultsfound'),
            filterPlaceholder: this.$t('search.searchText'),
        };
    },

    mounted() {
        this.detectMobile();

        if (this.modelValue) {
            this.setValue(this.modelValue);
        }

        if (this.isMobile) {
            window.addEventListener('resize', this.detectMobile);
        } else {
            if (this.closeOnScroll) {
                window.addEventListener('scroll', this.handleScroll);
            }
        }
    },

    beforeDestroy() {
        if (this.isMobile) {
            window.removeEventListener('resize', this.detectMobile);
        } else {
            if (this.closeOnScroll) {
                window.removeEventListener('scroll', this.handleScroll);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
:deep(.p-dropdown .p-dropdown-trigger) {
    .p-icon-spin {
        animation: loading 1s infinite linear;
    }
}

@keyframes loading {
    100% {
        transform: rotate(1turn);
    }
}

.phone-country-flag {
    margin-bottom: 1px !important;
}
</style>
