import { useApi } from '../../../libs/shared/composables/useApi.js';

export default defineNuxtPlugin(nuxtApp => {
    const { get, post, put, _delete } = useApi();

    nuxtApp.provide('getApi', get);
    nuxtApp.provide('postApi', post);
    nuxtApp.provide('putApi', put);
    nuxtApp.provide('deleteApi', _delete);
});
