export default defineNuxtRouteMiddleware(() => {
    const config = useRuntimeConfig();
    const projectName = config.public.projectName;
    if (projectName !== 'B2C') {
        return;
    }

    if (process.client) {
        const token = useCookie('token');
        const isAuthenticated = token.value;
        if (isAuthenticated) {
            return navigateTo('/');
        }
    }
});
