import { useAxios } from '@/composables/useAxios';

export const state = () => ({
    compareList: [],
    result: [],
    showDone: false,
});

export const actions = {
    addCompare({ state }, payload) {
        const form = new FormData();
        payload.dataCompare.forEach(item => {
            const key = Object.keys(item)[0];
            const value = item[key];
            form.append(key, value);
        });
        const { api } = useAxios();
        api.post('/compare', form).then(res => {
            state.result = res.data.data;
            payload.router.push('/profile/compare');
            // if (localStorage.getItem('APP_LANG') === 'ar') {
            //   payload.router.push("/ar/profile/compare");
            // } else {
            //   payload.router.push('/profile/compare');
            // }
        });
    },
};
