<template>
    <div class="form-box-container generalInquiryForm">
        <Form @submit="submitGeneralInquiry" :validation-schema="schema" ref="form">
            <div class="row">
                <div class="col-md-12">
                    <p class="prag" :class="[{ 'inputWidth pt-4': inputWidth }]">
                        {{ $t('generalInquiryPrag') }}
                    </p>
                </div>
                <div :class="[inputWidth ? 'col-md-12 pb-4' : 'col-xl-4 col-lg-6 col-md-6 col-12 pb-4']">
                    <div class="full-box">
                        <Field name="first_name" v-slot="{ field }">
                            <InputText
                                id="first_name"
                                class="w-100"
                                v-bind="field"
                                :placeholder="$t('profile.first_name')"
                            />
                        </Field>
                    </div>
                    <ErrorMessage class="text-danger" name="first_name" />
                </div>
                <div :class="[inputWidth ? 'col-md-12 pb-4' : 'col-xl-4 col-lg-6 col-md-6 col-12 pb-4']">
                    <div class="full-box">
                        <Field name="last_name" v-slot="{ field }">
                            <InputText
                                id="last_name"
                                class="w-100"
                                :placeholder="$t('profile.last_name')"
                                v-bind="field"
                            />
                        </Field>
                    </div>
                    <ErrorMessage class="text-danger" name="last_name" />
                </div>
                <div :class="[inputWidth ? 'col-md-12 pb-4' : 'col-xl-4 col-lg-6 col-md-6 col-12 pb-4']">
                    <div class="full-box">
                        <Field name="email" v-slot="{ field }">
                            <InputText id="email" class="w-100" :placeholder="$t('profile.email')" v-bind="field" />
                        </Field>
                    </div>
                    <ErrorMessage class="text-danger" name="email" />
                </div>
                <div :class="[inputWidth ? 'col-md-12 pb-4' : 'col-xl-4 col-lg-6 col-md-6 col-12 pb-4']">
                    <UtilsFormPhoneNumber required />
                </div>
                <div :class="[inputWidth ? 'col-md-12 pb-4' : 'col-xl-4 col-lg-6 col-md-6 col-12 pb-4']">
                    <UtilsFormCountrySelect
                        id="CountryOfResidence"
                        name="CountryOfResidence"
                        :placeholder="$t('Countryofresidence')"
                    />
                </div>
                <div :class="[inputWidth ? 'col-md-12 pb-4' : 'col-xl-4 col-lg-6 col-md-6 col-12 pb-4']">
                    <div class="input-box">
                        <Field v-slot="{ value, handleChange }" name="preferredDestination">
                            <UtilsGenericDropDown
                                :options="$store.state.destinations.destinationsList"
                                :modelValue="value"
                                @update:modelValue="handleChange"
                                :placeholder="`${$t('Preferreddestination')}`"
                                class="w-100 md:w-14rem"
                            />
                        </Field>
                        <ErrorMessage class="text-danger" name="preferredDestination" />
                    </div>
                </div>
                <hr />
                <div class="d-flex">
                    <p class="prag">
                        {{ $t('educationInfo') }}
                    </p>
                    <div class="optional">({{ $t('Optional') }})</div>
                </div>

                <p class="when-plan-to-study mb-2">
                    {{ $t('WhenPlanToStudy') }}
                </p>

                <div class="d-flex dropwdown-container">
                    <div class="input-box">
                        <Field v-slot="{ value, handleChange }" name="startMonth">
                            <Dropdown
                                filter
                                filterIcon="pi pi-search"
                                :options="monthList"
                                optionLabel="name"
                                :modelValue="value"
                                @update:modelValue="handleChange"
                                :placeholder="`${$t('month')}`"
                                class="w-100 md:w-14rem dropwdown-time"
                            />
                        </Field>
                        <ErrorMessage class="text-danger" name="startMonth" />
                    </div>

                    <div class="input-box">
                        <Field v-slot="{ value, handleChange }" name="startYear">
                            <Dropdown
                                filter
                                filterIcon="pi pi-search"
                                :options="year"
                                optionLabel="name"
                                :modelValue="value"
                                @update:modelValue="handleChange"
                                :placeholder="`${$t('year')}`"
                                class="w-100 md:w-14rem dropwdown-time"
                            />
                        </Field>
                        <ErrorMessage class="text-danger" name="startYear" />
                    </div>
                </div>
                <div class="clear-fix"></div>

                <div :class="[inputWidth ? 'col-md-12 pb-4' : 'col-xl-4 col-lg-6 col-md-6 col-12 pb-4']">
                    <div class="input-box">
                        <Field v-slot="{ value, handleChange }" name="degreeType">
                            <Dropdown
                                filter
                                filterIcon="pi pi-search"
                                :options="$store.state.degreeType.degreeTypeList"
                                optionLabel="name"
                                :modelValue="value"
                                @update:modelValue="handleChange"
                                :placeholder="`${$t('EducationQualifications')}`"
                                class="w-100 md:w-14rem"
                            />
                        </Field>
                        <ErrorMessage class="text-danger" name="degreeType" />
                    </div>
                </div>
                <div :class="[inputWidth ? 'col-md-12 pb-4' : 'col-xl-4 col-lg-6 col-md-6 col-12 pb-4']">
                    <div class="input-box">
                        <Field v-slot="{ value, handleChange }" name="educationalLevel">
                            <Dropdown
                                filter
                                filterIcon="pi pi-search"
                                :options="$store.state.educationalLevel.Levels"
                                optionLabel="name"
                                :modelValue="value"
                                @update:modelValue="handleChange"
                                :placeholder="`${$t('Preferredstudylevel')}`"
                                class="w-100 md:w-14rem"
                            />
                        </Field>
                        <ErrorMessage class="text-danger" name="educationalLevel" />
                    </div>
                </div>
                <div :class="[inputWidth ? 'col-md-12 pb-4' : 'col-xl-4 col-lg-6 col-md-6 col-12 pb-4']">
                    <div class="input-box">
                        <Field v-slot="{ value, handleChange }" name="studyFunding">
                            <Dropdown
                                filter
                                filterIcon="pi pi-search"
                                :options="setStudyFundingList"
                                :modelValue="value"
                                @update:modelValue="handleChange"
                                optionLabel="name"
                                :placeholder="`${$t('Studyfunding')}`"
                                class="w-100 md:w-14rem"
                            />
                        </Field>
                        <ErrorMessage class="text-danger" name="studyFunding" />
                    </div>
                </div>
                <div :class="[inputWidth ? 'col-md-12 pb-4' : 'col-xl-4 col-lg-6 col-md-6 col-12 pb-4']">
                    <div class="input-box">
                        <Field v-slot="{ value, handleChange }" name="hear_about_us">
                            <Dropdown
                                filter
                                filterIcon="pi pi-search"
                                :options="hear_about_us_list"
                                :modelValue="value"
                                @update:modelValue="handleChange"
                                optionLabel="name"
                                :placeholder="`${$t('hear_about_us')}`"
                                class="w-100 md:w-14rem"
                            />
                        </Field>
                        <ErrorMessage class="text-danger" name="hear_about_us" />
                    </div>
                </div>
                <div class="col-12 pb-4" v-if="false">
                    <p class="prag" :class="[{ inputWidth: inputWidth }]">
                        {{ $t('Acceptixprag') }}
                    </p>
                </div>
                <div class="col-md-12 mb-4" v-if="false">
                    <div class="d-flex gap-2 align-items-start remember-box-item">
                        <Checkbox v-model="acceptixAgree" class="mt-1" :binary="true" inputId="acceptixAgree" />
                        <label for="acceptixAgree"> {{ $t('Acceptixpragagree') }}</label>
                    </div>
                </div>
                <div class="col-md-12 mb-4" v-if="false">
                    <div class="d-flex gap-2 align-items-start remember-box-item">
                        <Checkbox v-model="acceptixQuiry" class="mt-1" :binary="true" inputId="acceptixQuiry" />
                        <label for="acceptixQuiry"> {{ $t('Acceptixpragenquiry') }}</label>
                    </div>
                </div>
                <div class="col-md-12 mb-4" v-if="false">
                    <div class="d-flex gap-2 align-items-start remember-box-item">
                        <Checkbox v-model="acceptixOffers" class="mt-1" :binary="true" inputId="acceptixOffers" />
                        <label for="acceptixOffers"> {{ $t('Acceptixpragenoffers') }}</label>
                    </div>
                </div>
                <div class="col-md-12">
                    <p class="accept-button">
                        {{ $t('youareacceptingour') }}
                        <LocLink to="/terms-and-conditions">
                            {{ $t('footer.TermsConditConditions') }}
                        </LocLink>
                        {{ $t('and') }}
                        <LocLink to="/privacy-and-policy">
                            {{ $t('footer.Privacypolicy') }}
                        </LocLink>
                    </p>
                </div>
                <div class="col-md-12" v-if="showError">
                    <AlertForm :title="formTitleError" :content="formContentError" />
                </div>
                <div class="col-md-12">
                    <div class="alert-submission mt-4" v-if="existBefore">
                        <div class="icon-box">
                            <i class="pi pi-exclamation-triangle"></i>
                        </div>
                        <div class="content-box">
                            <h4 class="title">{{ $t('ApplicationSubmission') }}</h4>
                            <p class="prag">{{ $t('ApplicationSubmissionPrag') }}</p>
                        </div>
                        <div class="close-item" @click="existBefore = false">
                            <i class="pi pi-times"></i>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 pt-4">
                    <div class="text-end button-end">
                        <div>
                            <Button
                                :class="[{ 'w-100': inputWidth }]"
                                type="submit"
                                style="min-width: 125px"
                                :label="$t('startButtonJourney')"
                                v-if="!loading"
                            />
                            <Button
                                v-else
                                style="min-width: 125px"
                                :class="[{ 'w-100': inputWidth }]"
                                disabled
                                icon="pi pi-spin pi-spinner"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
import { useAxios } from '@/composables/useAxios';
import * as yup from 'yup';
import { ErrorMessage, Field, Form } from 'vee-validate';

export default {
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    props: {
        inputWidth: {
            default: false,
        },
    },
    async mounted() {
        this.formRef = this.$refs.form;
        await this.$store.dispatch('destinations/getDestinations');
        await this.$store.dispatch('degreeType/getDegreeType');
        await this.$store.dispatch('educationalLevel/getLevels');
    },
    computed: {
        phoneValidationLength() {
            return this.values?.country_code?.numberLength ?? 10;
        },
        values() {
            return this.formRef?.values;
        },
        schema() {
            return yup.object().shape({
                first_name: yup
                    .string()
                    .matches(/^(?![\d])[\w@\-#_.]{3,20}$/, this.$t('registrationValidation.name.invalid'))
                    .trim()
                    .required(this.$t('registrationValidation.name.required.first')),
                last_name: yup
                    .string()
                    .matches(/^(?![\d])[\w@\-#_.]{3,20}$/, this.$t('registrationValidation.name.invalid'))
                    .trim()
                    .required(this.$t('registrationValidation.name.required.last')),
                email: yup
                    .string()
                    .email(this.$t('registrationValidation.email.invalid'))
                    .required(this.$t('registrationValidation.email.required')),
                country_code: yup.object().required(this.$t('theselected')),
                CountryOfResidence: yup.object().required(this.$t('Pleaseenteryourcountry')),
                phone_number: yup
                    .string()
                    .matches(/^[0-9]+$/, { message: this.$t('MobileValidation') })
                    .transform(value => (Number.isNaN(value) ? null : value))
                    .min(
                        this.phoneValidationLength,
                        `${this.$t('exactly')} ${this.phoneValidationLength} ${this.$t('digits')}`
                    )
                    .max(
                        this.phoneValidationLength,
                        `${this.$t('exactly')} ${this.phoneValidationLength} ${this.$t('digits')}`
                    )
                    .required(this.$t('PleaseenteryourphoneNumber')),
                preferredDestination: yup.object().required(this.$t('chooseDestination')),
                startMonth: yup.object(),
                startYear: yup.object(),
                degreeType: yup.object(),
                educationalLevel: yup.object(),
                studyFunding: yup.object(),
                hear_about_us: yup.object(),
            });
        },
        setStudyFundingList() {
            if (this.$i18n.locale == 'ar') {
                return this.studyFundingListAr;
            } else {
                return this.studyFundingListEn;
            }
        },
        monthList() {
            if (this.$i18n.locale == 'ar') {
                return this.monthAr;
            } else {
                return this.monthEn;
            }
        },
        hear_about_us_list() {
            if (this.$i18n.locale == 'ar') {
                return this.hear_about_us_listAr;
            } else {
                return this.hear_about_us_listEn;
            }
        },
    },
    data() {
        return {
            formRef: null,
            loading: false,
            existBefore: false,
            hideButton: true,
            first_name: '',
            last_name: '',
            email: '',
            selectedPhoneCode: null,
            selectedPhoneCode_error: false,
            phoneNumber: '',
            CountryOfResidence: null,
            preferredDestination: null,
            startYear: [],
            startMonth: [],
            degreeType: [],
            educationalLevel: [],
            studyFunding: [],
            hear_about_us: [],
            studyFundingListEn: [
                { id: 'self-funded', name: 'Self Funded' },
                { id: 'sponsored', name: 'Sponsored' },
            ],
            studyFundingListAr: [
                { id: 'self-funded', name: 'التمويل الذاتي' },
                { id: 'sponsored', name: 'برعاية' },
            ],
            year: [
                { id: 2024, name: 2024, value: 2024 },
                { id: 2025, name: 2025, value: 2025 },
                { id: 2026, name: 2026, value: 2026 },
                { id: 2027, name: 2027, value: 2027 },
                { id: 2028, name: 2028, value: 2028 },
                { id: 2029, name: 2029, value: 2029 },
            ],
            monthEn: [
                { id: 3, name: 'March' },
                { id: 4, name: 'April' },
                { id: 5, name: 'May' },
                { id: 6, name: 'June' },
            ],
            monthAr: [
                { id: 3, name: 'مارس' },
                { id: 4, name: 'أبريل' },
                { id: 5, name: 'مايو' },
                { id: 6, name: 'يونيو' },
            ],
            hear_about_us_listEn: [
                { name: 'Acceptix Website', id: 'Acceptix Website' },
                { name: 'Paid /Display Ad', id: 'Paid /Display Ad' },
                { name: 'Social Media', id: 'Social Media' },
                { name: 'Institution', id: 'Institution' },
                { name: 'Friends & Family', id: 'Friends & Family' },
                { name: 'Posters/Flyers', id: 'Posters/Flyers' },
            ],
            hear_about_us_listAr: [
                { name: 'Acceptix موقع', id: 'Acceptix Website' },
                { name: 'إعلان مدفوع /عرض ', id: 'Paid /Display Ad' },
                { name: 'وسائل التواصل الاجتماعي', id: 'Social Media' },
                { name: 'المؤسسة', id: 'Institution' },
                { name: 'الأصدقاء والعائلة', id: 'Friends & Family' },
                { name: 'الملصقات/النشرات', id: 'Posters/Flyers' },
            ],
            acceptixAgree: false,
            acceptixQuiry: false,
            acceptixOffers: false,
            //errors
            showError: false,
            phoneCodeError: false,
            codeError: false,
            phoneNumber_error: false,
            messagePhoneCodeError: '',
            messageCodeError: '',
            formTitleError: '',
            formContentError: '',
        };
    },
    methods: {
        // monthFilter(month) {
        //   const d = new Date();
        //   const currentMonth = d.getMonth() + 1;
        //   // const currentMonth = 4;

        //   if (currentMonth < 7 && currentMonth > 2) {
        //     return month.filter((element) => element.id > currentMonth);
        //   } else {
        //     return month.slice(); // Return a shallow copy of the original array
        //   }
        // },
        scrollDown() {
            this.hideButton = false;
            this.$refs.general_inquiry.scrollIntoView({ behavior: 'smooth' });
        },
        validateEmail() {
            this.email_error = false;
            const emailRegex = /^(?![\d])[\w@\-#_.$!؟)(*,]+$/;
            this.isValidEmail = emailRegex.test(this.email);
            if (!this.isValidEmail) {
                this.email_error = true;
                this.emailMessage = this.$t('EmailValidation');
            } else {
                this.emailMessage = '';
            }
        },
        validateField(fieldName, errorData) {
            this[`${fieldName}_error`] = false;
            const NameRegex = /^(?![\d])[\w@\-#_.]{3,20}$/;
            if (!NameRegex.test(this[fieldName])) {
                this[`${fieldName}_error`] = true;

                if (fieldName == 'first_name') {
                    this[`${fieldName}_error_message`] = this.$t('FirstNameValidation');
                } else if (fieldName == 'last_name') {
                    this[`${fieldName}_error_message`] = this.$t('LastNameValidation');
                } else {
                    this[`${fieldName}_error_message`] = this.$t(errorData.errorMessage);
                }
            } else {
                this[`${fieldName}_error_message`] = '';
            }
        },

        firstNameText() {
            this.validateField('first_name', {
                errorMessage: 'textGenericValidationError',
            });
        },

        lastNameText() {
            this.validateField('last_name', {
                errorMessage: 'textGenericValidationError',
            });
        },
        submitGeneralInquiry(values) {
            console.log(values);
            const self = this;
            this.existBefore = false;
            this.loading = true;
            const { api } = useAxios();
            api.post('/user/general-inquiry', {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                country_code: values.country_code.code,
                phone: values.phone_number,
                country_of_residence: values.CountryOfResidence.id,
                preferred_study_destination: values.preferredDestination.id,
                study_plan_year: values.startYear?.id,
                study_plan_month: values.startMonth?.id,
                degree_type: values.degreeType?.id,
                study_level: values.educationalLevel?.id,
                study_funding: values.studyFunding?.id,
                hear_about_us: values.hear_about_us?.id,
            })
                .then(res => {
                    self.loading = false;
                    self.$router.push(this.localePath('/thank-you'));
                })
                .catch(function (error) {
                    self.loading = false;
                    self.existBefore = true;
                });
        },
    },
};
</script>

<style lang="scss">
.generalInquiryForm {
    hr {
        border-color: #dfdfdf;
        opacity: 1;
        width: 98%;
        margin-left: auto;
        margin-right: auto;
    }

    .dropwdown-time {
        width: 100% !important;
    }

    .dropwdown-container {
        margin-bottom: 16px;
        gap: 12px;

        > div {
            width: 50%;
        }
    }

    .optional {
        margin: 0 10px;
        color: $color-gray-dark;
    }

    .when-plan-to-study {
        color: $color-primary-dark;
    }

    .p-inputtext {
        font-size: 14px;
    }
}
</style>
<style lang="scss" scoped>
.prag {
    font-size: 16px;
    font-weight: 700;
    color: $color-primary-dark;
    padding-bottom: 16px;

    &.inputWidth {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #000;
    }
}

.button-end {
    div {
        display: block;
        width: 100%;

        button {
            @include media(772) {
                width: 100%;
            }
        }
    }
}

.accept-button {
    font-size: 14px;
    font-weight: 400;
    color: #222222;

    a {
        color: $color-secondary;
    }
}
</style>
