<template>
    <div class="top-destinations-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <GeneralMinTitle
                        :title="$t('homePage.topDestinations.title')"
                        :prag="$t('homePage.topDestinations.subtitle')"
                    />
                </div>
            </div>
            <div class="row" v-if="!$store.state.destinations.loading">
                <div class="col-md-12">
                    <Skeleton width="100%" height="15rem" class="mx-2 mx-auto"></Skeleton>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-md-12">
                    <Swiper
                        :slides-per-view="1"
                        slidesPerView="1.2"
                        :slidesPerView="'auto'"
                        :dir="rtl"
                        :autoplay="{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }"
                        :pagination="{ clickable: true }"
                        :navigation="{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }"
                        :modules="[SwiperPagination, SwiperNavigation, SwiperAutoplay]"
                        :parallax="true"
                        spaceBetween="0"
                        pagination
                        :breakpoints="{
                            '442': {
                                slidesPerView: 1.2,
                            },
                            '640': {
                                slidesPerView: 1.2,
                            },
                            '993': {
                                slidesPerView: 2,
                            },
                            '1024': {
                                slidesPerView: 3,
                            },
                        }"
                    >
                        <swiper-slide v-for="item in destinations" :key="item.id">
                            <div class="destinations-card">
                                <img :src="item.image" alt="" class="img-fluid w-100" />
                                <div class="content-box">
                                    <h4 class="name">
                                        <LocLink @click.prevent="searchByDestination(item)">
                                            {{ item.name }}
                                        </LocLink>
                                    </h4>
                                    <LocLink @click.prevent="searchByDestination(item)">
                                        <div class="arrow-box">
                                            <i class="pi pi-arrow-left" v-if="rtl"></i>
                                            <i class="pi pi-arrow-right" v-else></i>
                                        </div>
                                    </LocLink>
                                </div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-button-next">
                            <i class="pi pi-chevron-right" style="color: #bdbdbd" v-if="locale == 'en'"></i>
                            <i class="pi pi-chevron-left" style="color: #bdbdbd" v-else></i>
                        </div>

                        <div class="swiper-button-prev">
                            <i class="pi pi-chevron-left" style="color: #bdbdbd" v-if="locale == 'en'"></i>
                            <i class="pi pi-chevron-right" style="color: #bdbdbd" v-else></i>
                        </div>
                    </Swiper>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useCheckCategory } from '../../../../libs/shared/composables/useCheckCategory';
const { categoryFilterCountries, getCategoryList, getCategoryCourses } = useCheckCategory();
import { useCommonStore } from '../../../../libs/shared/store/search/common';

const commonStore = useCommonStore();
const { category } = storeToRefs(commonStore);
const { locale } = useI18n();

const searchByDestination = item => {
    category.value = item.is_university ? 'university' : 'institute';
    if (!categoryFilterCountries.value.some(selected => selected.id === item.id)) {
        categoryFilterCountries.value.push({ id: item.id, text: item.name, type: 'country' });
        // call APIs
        getCategoryList.value();
        getCategoryCourses.value();
    }

    const destinationType = item.is_university ? 'university' : 'institute';
    const router = useRouter();
    router.push(`/${destinationType}`);
};
</script>

<script>
import destinationLink from '@/mixins/destinationLink';
export default {
    mixins: [destinationLink],
    async mounted() {
        await this.$store.dispatch('destinations/getDestinations');
    },
    computed: {
        rtl() {
            return this.$i18n.locale == 'ar';
        },
        destinations() {
            return this.$store.state.destinations.destinationsList;
        },
    },
};
</script>

<style lang="scss">
.top-destinations-section {
    .swiper {
        padding: 0 48px;
    }
}
</style>
<style lang="scss" scoped>
.swiper-button-next {
    right: 0;

    @include dir(rtl) {
        left: 0;
        right: unset;
    }
}

.swiper-button-prev {
    left: 0;

    @include dir(rtl) {
        right: 0;
        left: unset;
    }
}

.swiper-button-prev,
.swiper-button-next {
    height: 99%;
    top: 50%;
    padding: 2px;
    width: 56px;
    transform: translateY(-43%);
    background-color: #fff;

    &:after {
        display: none;
    }

    i {
        height: 37px;
        min-width: 37px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 1px 2px 10px #33333350;
        display: grid;
        place-content: center;
    }

    .pi {
        font-size: 22px;
    }
}

@include media(772) {
    .top-destinations-section {
        .swiper {
            padding: 0;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }
}
</style>
