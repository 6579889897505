<template>
    <div class="done-screen">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div id="done-screen" class="forms-box-shadow text-center">
                        <div class="content mx-auto" :class="[fullWidth ? 'w-100' : '']">
                            <img src="~/assets/img/doneScreen.webp" alt="doneScreen" class="img-fluid" />
                            <div class="title-box pt-4 pb-4">
                                <h3 class="text">
                                    {{ $t('Applicationsuccessfullysubmitted') }}
                                </h3>
                                <p class="prag pt-3" v-if="message">
                                    {{ message }}
                                </p>
                                <p class="prag pt-3" v-else>
                                    {{ $t('24hoursPrag') }}
                                    <span>{{ $t('24hours') }}</span>
                                </p>
                            </div>
                            <div class="button-container" v-if="buttonBox">
                                <LocLink v-if="backButton" to="/" class="p-button font-bold">{{ $t('done') }}</LocLink>
                                <div class="button-box d-flex align-items-center justify-content-center gap-4" v-else>
                                    <LocLink to="/" class="p-button font-bold">{{ $t('ReturntoHome') }}</LocLink>
                                    <Button @click="backPage" v-if="backButton" class="p-button font-bold">{{
                                        $t('back')
                                    }}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        backButton: {
            default: false,
        },
        buttonBox: {
            default: true,
        },
        fullWidth: {
            default: false,
        },
        message: {
            type: String,
            required: false,
            default: null,
        },
    },
    mounted() {
        setTimeout(() => {
            const element = document.getElementById('done-screen');
            element.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
    },
    methods: {
        backPage() {
            this.$router.go(-1);
        },
    },
};
</script>

<style lang="scss" scoped>
.forms-box-shadow {
    padding: 40px;

    .content {
        width: 50%;

        @include media(992) {
            width: 100%;
        }
    }
}

.prag {
    color: #797979;
    text-align: center;
    font-size: 16px;
    font-weight: 400;

    span {
        font-weight: 600;
        color: $color-black;
    }
}
</style>
