import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig();

    if (config?.public?.env === 'production') {
        Sentry.init({
            app: nuxtApp.vueApp,
            dsn: config?.public?.sentryDSN,
            environment: config?.public?.env,
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', config.public.baseURL],
            // Session Replay
            replaysSessionSampleRate: config?.public?.sentrySessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
});
