<template>
    <div class="notification-box-show">
        <div class="have-notification" v-if="$store.state.notification.notificationListBell.length > 0">
            <ul>
                <li v-for="item in $store.state.notification.notificationListBell">
                    <LocLink to="/profile/notifications">
                        <div class="notification-content">
                            <div class="bell-box" :class="{ active: item.read === 0 }">
                                <i class="pi pi-bell"></i>
                            </div>
                            <div class="content">
                                <h3 class="name text-truncate">{{ item.title }}</h3>
                                <p class="prag text-truncate">{{ item.notification }}</p>
                            </div>
                            <div class="time-box">
                                <h4 class="time-item">{{ item.sent_at }}</h4>
                                <div class="ball" :class="{ active: item.read === 0 }"></div>
                            </div>
                        </div>
                    </LocLink>
                </li>
            </ul>
            <div class="show-all">
                <LocLink to="/profile/notifications">
                    {{ $t('seemorenotification') }}
                </LocLink>
            </div>
        </div>
        <div class="empty" v-else>
            <img src="~/assets/img/empty-notifcation.svg" alt="" class="img-fluid" />
            <h4 class="empty-title">{{ $t('notificationEmpty') }}</h4>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        formatTime(date) {
            const dateTime = new Date(date);
            const options = {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            };

            return dateTime.toLocaleTimeString(this.$i18n.locale, options);
        },
    },
};
</script>

<style lang="scss" scoped>
.notification-box-show {
    position: absolute;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    top: 40px;
    right: 0;
    width: 389px;
    z-index: 2;
    ul {
        li {
            a {
                display: block;
                padding: 0 20px;
                @include hover(0.2) {
                    background-color: rgba(0, 0, 0, 0.02);
                }
                .notification-content {
                    gap: 8px;
                    padding: 20px 0;
                    align-items: center;
                    display: flex;
                    border-bottom: 1px solid #dadada;

                    .bell-box {
                        min-width: 24px;
                        min-height: 24px;
                        border-radius: 50%;
                        display: grid;
                        place-content: center;
                        background-color: #ededed1a;
                        color: #d9d9d9;
                        &.active {
                            background-color: #ffedd5;
                            color: #f36347;
                        }
                    }
                    .content {
                        .name {
                            color: #222;
                            padding-bottom: 4px;
                            font-size: 14px;
                            font-weight: 600;
                        }
                        .prag {
                            color: #828282;
                            font-size: 12px;
                            font-weight: 300;
                            max-width: 261px;
                        }
                    }
                    .time-box {
                        @include dir(rtl) {
                            margin-right: auto;
                        }
                        @include dir(ltr) {
                            margin-left: auto;
                        }
                        .time-item {
                            color: #828282;
                            font-size: 10px;
                            font-weight: 500;
                            padding-bottom: 8px;
                        }
                        .ball {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: #3b6c89;
                            margin: 0 auto;
                            opacity: 0;
                            &.active {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
    .show-all {
        a {
            display: block;
            padding: 20px 0;
            color: #3b6c89;
            font-size: 16px;
            text-align: center;
            font-weight: 500;
            @include hover(0.2) {
                background-color: rgba(0, 0, 0, 0.02);
            }
        }
    }
    .empty {
        display: grid;
        height: 308px;

        place-content: center;
        img {
            margin: 0 auto;
        }
        .empty-title {
            color: #dfdfdf;
            font-size: 20px;
            font-weight: 500;
            padding-top: 24px;
        }
    }
}
</style>
