import { useAxios } from '@/composables/useAxios';
export const state = () => ({
    token: [],
});

export const actions = {
    login({ state }, payload) {
        const { api } = useAxios();
        api.post('/auth/register', {
            email: payload.email,
            password: payload.password,
        }).then(res => {
            state.countries = res.data.data;
            localStorage.setItem('userlogin', true);
            // if (localStorage.getItem("APP_LANG") === "ar") {
            //   this.$router.push("/ar/");
            // } else {
            this.$router.push('/');
            // }
        });
    },
};
