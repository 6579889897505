export const state = () => ({
    token: '',
    name: '',
    logout: false,
    showAlert: false,
    profileMenu: true,
});

export const actions = {
    showAlert({ state }) {
        state.showAlert = true;
    },
};
