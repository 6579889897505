<script>
import { ErrorMessage, Field } from 'vee-validate';

export default {
    name: 'InputText',

    components: {
        ErrorMessage,
        Field,
    },

    props: {
        id: {
            type: String,
            required: true,
        },

        name: {
            type: String,
            required: true,
        },

        type: {
            type: String,
            default: 'text',
        },

        placeholder: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<template>
    <div class="input-box">
        <Field v-slot="{ field }" :name="id">
            <InputText
                :id="id"
                :disabled="disabled"
                :name="name"
                :placeholder="placeholder"
                :type="type"
                aria-describedby="name-help"
                class="w-100"
                max="100"
                v-bind="field"
            />
        </Field>
        <ErrorMessage :name="name" class="text-danger" />
    </div>
</template>
