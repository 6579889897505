import flags from '../src/countries.json';

export const useCountriesStore = defineStore('countries', () => {
    const { $getApi } = useNuxtApp();

    const countries = ref([]);
    const loading = ref(true);

    const getCountries = async () => {
        const res = await $getApi(`countries/registration`);
        countries.value = res.data?.data || [];
        countries.value = countries.value
            .filter(resItem => Boolean(resItem.iso_code))
            .map(resItem => {
                const country = flags?.data?.find(c => c.iso_code === resItem.iso_code);
                if (!country) return null;

                const show = country.flag || country.emoji || '';
                return {
                    id: resItem.id,
                    show,
                    code: resItem.code ?? '',
                    flag: country.flag ?? '',
                    name: resItem.name ?? '',
                    numberLength: resItem.number_length ?? 0,
                };
            })
            .filter(Boolean);
        loading.value = false;
    };

    return { countries, loading, getCountries };
});
