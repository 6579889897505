export default {
    methods: {
        getDestinationLink(destination) {
            let destinationType;
            if (destination.is_university) {
                destinationType = 'university';
            } else {
                destinationType = 'institute';
            }

            return {
                path: this.localePath(`/${destinationType}`),
                query: {
                    country_id: destination.id,
                    country_name: destination.name,
                    is_university: destination.is_university,
                    is_institute: destination.is_institute,
                },
            };
        },
    },
};
