<template>
    <div>
        <HomeIntro />
        <HomeAcceptixNumbers />
        <HomeGeneralInquiry class="top-destinations" />
        <HomeTopDestinations class="top-destinations" />
        <HomeReferralSection v-if="featuresStore.isShowReferral" />
        <HomeTestimonials />
        <div class="howWorkSection">
            <HomeJourneySection />
            <HomeHowWork class="howWorkSection" id="howWorkSection" />
        </div>

        <!-- <HomeTopUniversities /> -->

        <EventsSlider />
        <HomeApplicationSection />
        <HomeSuggestedSubjects />
        <HomeCertified />
        <HomeQuickApplyButton />
    </div>
</template>

<script setup>
import { useCommonStore } from '../../../libs/shared/store/search/common';
const commonStore = useCommonStore();
const { preventClear } = storeToRefs(commonStore);

onMounted(() => {
    preventClear.value = false;
});
</script>

<script>
import EventsSlider from '../components/Events/eventsSlider.vue';
import { useFeaturesStore } from '../store/features.js';
export default {
    async mounted() {
        await this.$store.dispatch('educationalLevel/getEducationalLevel');
        await this.$store.dispatch('countries/getCountries');
        localStorage.removeItem('pageLink');
        const token = useCookie('token');
        if (token.value) {
            await nextTick();
            await this.$store.dispatch('profile/getProfile');
        }
    },
    data() {
        return {
            tags: ['Entery', ' 33 lessons/week'],
            featuresStore: useFeaturesStore(),
        };
    },
};
</script>

<style lang="scss" scoped>
.top-destinations {
    margin: 62px 0;
}

//// old
// .intro-section {
//   margin: -100px 0 0;
// }
.ourNetworkSection {
    margin: 88px 0 0;
}

.howWorkSection {
    margin: 62px 0;
}

.servicesSection {
    padding: 0 0 110px 0;
}

.testimonialsSection {
    padding: 0 0 110px 0;
}

@media (max-width: 772px) {
    .howWorkSection {
        margin: 40px 0;
    }
}

.flyButton {
    position: fixed;
    z-index: 9999;
    bottom: 30px;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 10px;

    a {
        display: block;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        background-color: #f36347;
        padding: 14px 34px;
        border-radius: 10px;

        @include hover(0.2) {
            background-color: $color-primary;
        }

        @include media(772) {
            font-size: 12px;
            padding: 8px 24px;
        }
    }

    @include dir(ltr) {
        right: 30px;
    }

    @include dir(rtl) {
        left: 30px;
    }
}
</style>
